import React from "react";
import "../sub-components/Card.css";

export default function Card({
  tag,
  price,
  percentage,
  coin,
  coinImg,
  profit,
}) {
  return (
    <div className="card1     h-[187px] w-[350px] rounded-xl border-[1px] border-[#222] bg-[#171717] p-4 after:rounded-xl  ">
      <div className="content-container absolute bottom-5">
        {coin || coinImg ? (
          <div className="flex items-center justify-start gap-5">
            <img
              src={coinImg}
              className="h-6 w-6 rounded-full"
              alt="coin logo"
            />
            <p className="text-[24px] font-light uppercase text-[#fff]">
              {coin}
            </p>
          </div>
        ) : (
          <div></div>
        )}

        <div>
          <p className="tag text-[20px] font-light">{tag}</p>
          <h4
            className={`text-[24px] font-bold ${
              !profit ? "text-red-500" : "text-green-500"
            }`}
          >
             {profit ? "+" : "-"}
            ${
              //if price is -ve then show it as +ve
              price < 0 ? -price : price

            }
          </h4>
          <p
            className={`! text-[12px] font-extralight ${
              !profit ? "text-red-500" : "text-green-500"
            }`}
          >
            {profit ? "+" : ""}
            {percentage}%
          </p>
        </div>
      </div>
    </div>
  );
}
import React, { useEffect, useState } from "react";
import TransactionContainer from "./Sections/TransactionContainer";
import glowPink from "../../../assets/img/landings/glowpink.png";
import { IoMdAdd } from "react-icons/io";

import axios from "axios";
function EditIcon() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.2245 1.31736C15.2941 1.1806 16.3761 1.61796 17.4756 2.65246L17.4768 2.65366C18.58 3.6969 19.0822 4.75519 19.0068 5.83308C18.934 6.87465 18.3302 7.75621 17.6417 8.48364M17.6417 8.48364L10.1196 16.4455C9.90753 16.6764 9.622 16.872 9.35131 17.0167C9.07714 17.1633 8.76001 17.2888 8.46228 17.3417L8.4577 17.3425L5.50709 17.8465C4.79157 17.9699 4.10545 17.7911 3.61668 17.3275C3.1286 16.8647 2.91292 16.1897 2.99282 15.4706L2.99305 15.4685L3.33369 12.4856C3.37325 12.1889 3.48345 11.8678 3.61335 11.5893C3.74278 11.3117 3.91938 11.0174 4.12836 10.795L4.12971 10.7935L11.6555 2.82769C12.3444 2.09998 13.191 1.44952 14.2245 1.31736M12.6545 3.77247C12.6544 3.77259 12.6547 3.77235 12.6545 3.77247L5.13053 11.7364C5.13037 11.7365 5.13069 11.7362 5.13053 11.7364C5.05539 11.8166 4.95217 11.9717 4.8595 12.1704C4.76851 12.3655 4.71386 12.546 4.69748 12.662L4.35941 15.6224C4.35937 15.6227 4.35934 15.623 4.3593 15.6234C4.32034 15.9762 4.43 16.2039 4.56284 16.3298C4.69511 16.4553 4.92565 16.5515 5.27347 16.4915L5.27453 16.4913L8.22354 15.9876C8.33838 15.9668 8.51531 15.9045 8.703 15.8042C8.89329 15.7024 9.03643 15.5927 9.10808 15.5141L9.11629 15.5051L16.6422 7.53936C17.2552 6.89188 17.5957 6.30186 17.6352 5.73721C17.6721 5.20878 17.4549 4.52563 16.5328 3.65338C15.6159 2.79081 14.9243 2.61408 14.3989 2.68126C13.8376 2.75304 13.2672 3.12542 12.6545 3.77247Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.7927 3.9497C11.1679 3.89125 11.5194 4.148 11.5778 4.52317C11.9235 6.74207 13.7244 8.4403 15.9633 8.6658C16.341 8.70385 16.6165 9.04095 16.5784 9.41873C16.5404 9.79652 16.2033 10.0719 15.8255 10.0339C12.9677 9.74605 10.6619 7.57594 10.2192 4.73484C10.1608 4.35967 10.4175 4.00815 10.7927 3.9497Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.0625 20.1665C2.0625 19.7868 2.3703 19.479 2.75 19.479H19.25C19.6297 19.479 19.9375 19.7868 19.9375 20.1665C19.9375 20.5462 19.6297 20.854 19.25 20.854H2.75C2.3703 20.854 2.0625 20.5462 2.0625 20.1665Z"
        fill="white"
      />
    </svg>
  );
}
export default function TransactionAdmin() {

  const [transactiondata, setTransactiondata] = useState([]);
  const [totalTransaction, setTotalTransaction] = useState(0);
  useEffect(() => {
    axios
    .get(process.env.REACT_APP_BASE_URL + "/transaction/gettransaction",{
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    })
    .then((res) => {
      setTransactiondata(res.data);
      setTotalTransaction(res.data.length);

    });

}, []);

  return (
    <div className="relative flex flex-col  text-white lg:p-4">
      <div className="my-5">
        Total Transaction
        <div className="my-2 flex justify-between">
          <div className="text-gd text-4xl font-bold">{totalTransaction && totalTransaction}</div>
          <div className="flex  md:gap-3">
            <button className="flex scale-[.87] items-center justify-center gap-1 rounded-lg bg-[#1A1D1E] px-2.5 py-1.5 text-[14px] md:scale-100">
              <div className="scale-[.85]">
                <EditIcon />
              </div>
              Edit
            </button>
            <button className="bg-gd flex scale-[.87] items-center justify-center gap-1 rounded-lg px-2.5 py-1.5 text-[14px] md:scale-100">
              <IoMdAdd /> Add a Transaction
            </button>
          </div>
        </div>
      </div>
      <div className="z-30">
        <TransactionContainer transactiondata={transactiondata}/>
      </div>
    </div>
  );
}

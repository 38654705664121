import React from "react";
import TransactionContainer from "./Sections/TransactionContainer";
import glowPink from "../../../assets/img/landings/glowpink.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
export default function Transaction() {
  const navigate = useNavigate();
  React.useEffect(() => {
    const id = localStorage.getItem("id");
    axios.get(process.env.REACT_APP_BASE_URL + `/user/getuser`,{
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    }).then((res) => {
      const user = res.data.find((item) => item._id === id);
      if (!user.client) {
        navigate("/user/profile");
      }
    });
  }
  , []);
  return (
    <div className="relative  text-white lg:p-4">
      <div className="z-30">
        <TransactionContainer />
      </div>
      <img
        src={glowPink}
        draggable="false"
        className="absolute top-[-16rem] left-[-16rem] z-[-1] h-[42rem] opacity-60 "
        alt=""
      />
    </div>
  );
}

import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Header from "./components/Header";
import MainSection from "./sections/MainSection";
import AboutSection from "./sections/AboutSection";
import CollectionSection from "./sections/CollectionSection";
import FAQSection from "./sections/FAQSection";
import FeaturesSection from "./sections/FeaturesSection";
import FooterSection from "./sections/FooterSection";
import Popularity from "./sections/Popularity";
import PromoSection from "./sections/PromoSection";
import Contact from "./sections/Contactus";
import WhyChooseUs from "./sections/whychooseus";
import Stats from "./sections/stats";
import SliderArticle from "./sections/SliderArticle";
import SliderUsers from "./sections/SliderUsers";
import ContactUs2 from "./sections/ContactUs2";
import InvestedBy from "./sections/InvestedBy";
import { useLocation } from "react-router-dom";

export default function Landing() {
  const location = useLocation();
  
  useEffect(() => {
    if (location.hash) {
      const id = location.hash.substring(1); // Remove the # from the hash
      const element = document.getElementById(id);
      
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  
  
  useEffect(() => {
    
    AOS.init({ duration: 1000 });
  }, []);
  //id is the parameter passed in the url
 

  return (
    <main className="app-bg scroll-container min-h-[100dvh] max-w-full overflow-x-hidden">
      <section className="center col flex gap-3 md:gap-3 xl:px-0">
        <Header
          data-aos="fade-down"
        gd={true} />
        <MainSection />
        <FeaturesSection gd={true} />
       
        <Stats />
        <SliderArticle />
        <WhyChooseUs />
        <FAQSection />
        <InvestedBy />
        <SliderUsers />
        <ContactUs2 />
        <FooterSection />
      </section>
    </main>
  );
}

import React, { useState, useEffect } from "react";
import axios from 'axios';
import Card from "components/card";
import LineChart from "components/charts/LineChart";

const TotalSpent = () => {
  const [coinData, setCoinData] = useState([]);

  const uid = localStorage.getItem("id");

  useEffect(() => {
    const fetchData = async (uid) => {
      try {
        // Fetch the current investments
        const portfolioResponse = await axios.get(
          process.env.REACT_APP_BASE_URL + `/investment/getcurrentinvestment`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          }
        );
        const coininfo = portfolioResponse.data;
        const usercoininfo = coininfo.filter((coin) => coin.uid === uid);

        // Fetch the live coin prices
        const listResponse = await axios.get(process.env.REACT_APP_BASE_URL + `/api/list`);
        const coinlist = listResponse.data.data;

        // Calculate PNL for each coin
        const coinsWithLivePrices = usercoininfo.map(coin => {
          const matchingCoin = coinlist.find(list => list.symbol === coin.coincode); // Ensure the IDs match
          if (matchingCoin) {
            coin.liveprice = matchingCoin.quote.USD.price; // Assuming the live price is here
          }
          return coin;
        });

        const pnlData = coinsWithLivePrices.map(coin => ({
          name: coin.coinname,
          data: [
            {
              date: new Date(), // You can replace this with the actual date if available
              pnl: (coin.liveprice - coin.coinprice) * coin.coinquantity, // PNL calculation
            }
          ],
        }));

        // Sort coins by PNL in descending order
        const sortedPnlData = pnlData.sort((a, b) => b.data[0].pnl - a.data[0].pnl);

        // Take the top five coins and group the rest under "Others"
        const topFiveCoins = sortedPnlData.slice(0, 5);
        const othersCoins = sortedPnlData.slice(5);

        if (othersCoins.length > 0) {
          const othersPnl = othersCoins.reduce((acc, coin) => acc + coin.data[0].pnl, 0);
          topFiveCoins.push({
            name: "Others",
            data: [
              {
                date: new Date(),
                pnl: othersPnl,
              }
            ],
          });
        }

        setCoinData(topFiveCoins);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData(uid);
  }, [uid]);

  return (
    <Card extra="!p-[50px] text-center">
      <div className="flex h-full w-full flex-col justify-around sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden">
        <div className="flex flex-col m-10 text-left">
          <h4 className="mt-[20px] text-xl text-navy-700 dark:text-white">
            Cumulative PNL
          </h4>
        </div>
        <div className="h-full w-full">
          <LineChart data={coinData} />
        </div>
      </div>
    </Card>
  );
};

export default TotalSpent;

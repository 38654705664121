import Card from "components/card";

const Widget = ({ icon, title, subtitle }) => {
  return (
    <Card extra="!flex-row flex-grow w-full items-center ">
      <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
        <div
          className=" bg-[#222222] 

        p-3 "
          style={{
            borderRadius: "13px",
            width: "50px",
            height: "50px",
          }}
        >
          <span
            className="flex 
            
          items-center text-brand-500 dark:text-white"
          >
            {icon}
          </span>
        </div>
      </div>

      <div className="h-50 ml-4 flex w-auto flex-col justify-center">
        <p className="font-dm text-sm font-medium text-gray-600">{title}</p>
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          {subtitle}
        </h4>
      </div>
    </Card>
  );
};

export default Widget;

import React, { useEffect } from "react";
import { GoTriangleDown } from "react-icons/go";
import axios from "axios";
import Icon from "./icon.json";

export default function Table() {
  const [data, setData] = React.useState(null);
  const uid = localStorage.getItem("id");

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  useEffect(() => {
    const fetchCoinData = async () => {
      try {
        const [coinlistRes, coininfoRes] = await Promise.all([
          axios.get(process.env.REACT_APP_BASE_URL + `/api/list`),
          axios.get(process.env.REACT_APP_BASE_URL + `/portfolio/coins`,{
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          }),
        ]);

        const coinlist = coinlistRes.data.data;
        const coininfo = coininfoRes.data;

        const usercoininfo = coininfo.filter((coin) => coin.uid === uid);

        if (usercoininfo.length > 0) {
          const icon = Icon;

          usercoininfo.forEach((coin) => {
            const coinData = coinlist.find((c) => c.symbol === coin.symbol);

            if (coinData) {
              const price = coinData.quote.USD.price;
              const volume_change_24h = coinData.quote.USD.volume_change_24h;
              const coinicon = icon.data[coinData.id];

              coin.liveprice = price;
              coin._24h = volume_change_24h;
              coin.icon = coinicon.logo;
            }
          });

          setData(usercoininfo);
        }
      } catch (error) {
        console.error("Error fetching coin data:", error);
      }
    };

    fetchCoinData();
  }, [uid]);

  const tableStyle = {
    width: "100%",
  };

  const cellStyle = {
    padding: "8px",
    textAlign: "left",
  };

  if (!data) {
    return <div>No data to show</div>;
  }

  return (
    <table
      style={tableStyle}
      className="overflow-x-auto !rounded-xl text-[14px] font-light"
    >
      <thead className="rounded-xl text-left text-[#8C8C8C]">
        <tr>
          <th style={cellStyle}>Name</th>
          <th style={cellStyle}>Price (USD)</th>
          <th style={cellStyle} className="flex flex-row items-center pl-2">
            24h <GoTriangleDown />
          </th>
          <th style={cellStyle}>Holdings</th>
          <th style={cellStyle}>Avg. buy price</th>
          <th style={cellStyle}>Profit</th>
        </tr>
      </thead>
      <tbody className="rounded-b-xl pb-5 text-left">
        {data.map((item, index) => (
          <tr key={index} className="bg-[#25252500]">
            <td style={cellStyle} className="min-w-fit p-8">
              <div className="flex items-center gap-2">
                <img
                  src={item.icon}
                  alt="icon"
                  className="h-8 w-8 rounded-full"
                />
                <div className="flex items-baseline gap-1">
                  <span>{item.name}</span>
                  <span className="text-[12px] uppercase text-[#717171]">
                    {item.symbol}
                  </span>
                </div>
              </div>
            </td>
            <td style={cellStyle}>
              <span>{formatter.format(item.liveprice)}</span>
            </td>
            <td style={cellStyle}>
              <div className="flex items-center gap-2">
                <span
                  className={`${
                    item._24h > 0 ? "text-green-500" : "text-red-500"
                  }`}
                >
                  ${item._24h.toFixed(2)}
                </span>
              </div>
            </td>
            <td style={cellStyle}>
              <div className="flex flex-col">
                <span>
                  {formatter.format(item.liveprice * item.totalQuantity)}
                </span>
                <span className="text-[12px] uppercase text-[#8C8C8C]">
                  {item.totalQuantity} {item.symbol}
                </span>
              </div>
            </td>
            <td style={cellStyle}>
              <span>{formatter.format(item.averagePrice)}</span>
            </td>
            <td style={cellStyle}>
              <div className="flex flex-col">
                <span>
                  {formatter.format(
                    item.liveprice * item.totalQuantity -
                      item.averagePrice * item.totalQuantity
                  )}
                </span>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

import React, { useState, useEffect } from "react";
import { SlOptionsVertical } from "react-icons/sl";
import axios from "axios";

import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Vector from "./../../../../assets/img/avatars/avatar1.png";
import InputField from "components/fields/InputField"; // Make sure this path is correct
import Card from "components/card"; // Make sure this path is correct
import { Link } from "react-router-dom";
import { IoMdAdd } from "react-icons/io";
export default function UserTable({ columnData, cellData }) {
  const [data, setData] = useState(cellData);
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const itemsPerPage = 5; // Set the number of items per page

  useEffect(() => {
    setData(cellData);
  }, [cellData]);

  const handleAddUser = () => {
    if (password === confirmPassword) {
      const client = true;
      const user = {
        name,
        username,
        email,
        phone,
        password,
        client,
        
      };
      axios
        .post(process.env.REACT_APP_BASE_URL + "/auth/user/register", user)
        .then((response) => {
          if (response.status === 200) {
            alert("User added successfully");
            setShowModal(false);
            setData([...data, user]);
          }
        })
        .catch((error) => {
          alert(error.response.data);
        });
    } else {
      alert("Passwords do not match");
    }
  };

  const handleUserDelete = async (id) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL + "/investment/getcurrentinvestment",{
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        }
      );
      if (response.status === 200) {
        const investments = response.data;
        const userInvestments = investments.filter((inv) => inv.uid === id);

        const confirmed = window.confirm(
          userInvestments.length > 0
            ? "User has ongoing investments. Are you sure you want to delete this user?"
            : "Are you sure you want to delete this user?"
        );

        if (confirmed) {
          await deleteUser(id);
        }
      }
    } catch (error) {
      alert(error.response.data);
    }
  };

  const deleteUser = async (id) => {
    try {
      const response = await axios.delete(
        process.env.REACT_APP_BASE_URL + "/user/deleteuser/" + id
        ,{
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        }
      );
      if (response.status === 200) {
        setData(data.filter((user) => user._id !== id));
        alert("User deleted successfully");
      }
    } catch (error) {
      alert(error.response.data);
    }
  };

  const toggleDropdown = (id) => {
    setActiveDropdown(activeDropdown === id ? null : id);
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const nextPage = () => {
    if (currentPage < Math.ceil(data.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className={"mt-8 h-full w-full p-4 sm:overflow-x-auto"}>
      <div className="relative flex items-center justify-between">
        <div className="text-2xl font-bold text-navy-700 dark:text-white">
          User Details
        </div>
        <button
          type="button"
          onClick={() => setShowModal(true)}
          className="bg-gd hover:bg-gd 4 mb-2 flex 
           items-center justify-center rounded-lg px-2.5 py-1.5 text-sm font-medium text-white me-2 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          <IoMdAdd className="mr-1 text-[16px]" />
          Add User
        </button>
        {showModal && (
          <>
            <div
              className="fixed inset-0 z-50 flex w-full items-center justify-center
               overflow-y-auto overflow-x-hidden
            rounded-lg  bg-opacity-70  outline-none focus:outline-none"
            >
              <div
                className="relative my-6 mx-auto max-w-4xl bg-[#252525] text-white"
                style={{
                  width: "100%",
                  borderRadius: "20px",
                  border: "1px solid #404040",
                  // background: "#252525",
                  backdropFilter: "blur(25px)",
                }}
              >
                {/*content*/}
                <div className=" relative flex w-full flex-col p-10">
                  {/*header*/}
                  <div className="flex items-start justify-between rounded-t border-b border-solid pt-5 pb-5">
                    <h3 className="flex gap-1 text-3xl font-semibold">
                      Add User
                    </h3>
                  </div>
                  {/*body*/}
                  <div className="relative ">
                    <div
                      className="mt-7 grid w-full grid-cols-1 gap-7 md:grid-cols-1 lg:grid-cols-2"
                      style={{
                        color: "#ACACAC",
                      }}
                    >
                      <div>
                        <p className="text-base font-medium leading-none ">
                          Full Name
                        </p>

                        <InputField
                          className="border-white-300 focus:bg-white-50 mt-4 w-full rounded border p-3 outline-none"
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                      <div>
                        <p className="text-white-800 text-base font-medium leading-none">
                          Username
                        </p>
                        <InputField
                          className="border-white-300 focus:bg-white-50 mt-4 w-full rounded border p-3 outline-none"
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      </div>
                      <div>
                        <p className="text-white-800 text-base font-medium leading-none">
                          email
                        </p>
                        <InputField
                          className="border-white-300 focus:bg-white-50 mt-4 w-full rounded border p-3 outline-none"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>

                      <div>
                        <p className="text-white-800 text-base font-medium leading-none">
                          Phone Number
                        </p>
                        <InputField
                          className="border-white-300 focus:bg-white-50 mt-4 w-full rounded border p-3 outline-none"
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                      <div>
                        <p className="text-white-800  text-base font-medium leading-none">
                          Password
                        </p>
                        <InputField
                          className="border-white-300 focus:bg-white-50 mt-4 w-full rounded border p-3 outline-none"
                          type="password"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                      <div>
                        <p className="text-white-800  text-base font-medium leading-none">
                          Confirm Password
                        </p>
                        <InputField
                          className="border-white-300 focus:bg-white-50 mt-4 w-full rounded border p-3 outline-none"
                          type="password"
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  {/*footer*/}
                  <div className="mt-10 flex items-center justify-between rounded-b">
                    <button
                      className="text-[#F9F9F9]-500 outline-nonemr-1 mb-1 flex flex-row gap-2 px-6 py-2 text-sm font-bold uppercase transition-all duration-150 ease-linear"
                      type="button"
                      onClick={() => setShowModal(false)}
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 100 100"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <line
                          x1="10"
                          y1="10"
                          x2="90"
                          y2="90"
                          stroke="#F9F9F9"
                          strokeWidth="10"
                        />
                        <line
                          x1="90"
                          y1="10"
                          x2="10"
                          y2="90"
                          stroke="#F9F9F9"
                          strokeWidth="10"
                        />
                      </svg>{" "}
                      Cancel
                    </button>
                    <button
                      className="bg-gd  active:bg-emerald-600 mr-1 mb-1 rounded px-6 py-3 text-sm font-bold uppercase text-white shadow outline-none transition-all duration-150 ease-linear hover:shadow-lg"
                      type="button"
                      onClick={() => handleAddUser()}
                    >
                      Create User
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <table className="my-6 w-full rounded-xl text-[17px]">
        <thead className="rounded-xl">
          <tr>
            {columnData.map((data, index) => (
              <th
                className="h-[72px] truncate px-4 py-2 text-left font-semibold text-white lg:px-10"
                key={index}
              >
                {data.Header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="w-full rounded-b-xl pb-5">
          {currentItems.length < 1 ? (
            <tr className="my-6 w-full">
              <td className="w-full text-center" colSpan={columnData.length}>
                <h1>No Transaction</h1>
              </td>
            </tr>
          ) : (
            currentItems.map((item, index) => (
              <tr key={index} className="mb-6">
                <td className="h-[72px] px-4 py-2 text-white">
                  <div className="flex gap-1">
                    <img
                      src={item.userImg ? item.userImg : Vector}
                      className="h-9 w-9 rounded-full object-cover object-center"
                      alt=""
                    />
                    <div className="flex flex-col gap-1">
                      <h1 className="text-[10px] font-bold text-white">
                        {item.username}
                      </h1>
                      <p className="text-[10px] font-medium text-[#ACACAC]">
                        {item.email}
                      </p>
                    </div>
                  </div>
                </td>
                <td className="px-4 py-2 text-center text-xs text-white">
                  <span>{item.phone}</span>
                </td>
                <td className="flex items-center justify-center px-4 py-2 text-center text-xs text-white">
                  <div
                    className={`w-[90px] rounded-md border-[.8px] py-2 ${
                      !item.phone
                        ? "border-[#E0042C] bg-[#E0042C]/10 text-[#E0042C]"
                        : "border-[#2DC24E] bg-[#2DC24E]/10 text-[#2DC24E]"
                    }`}
                  >
                    {!item.phone ? "Inactive" : " Active"}
                  </div>
                </td>
                <td className="px-4 py-2 text-center text-xs text-white">
                  <span>{formatter.format(item.totalInvestment)}</span>
                </td>
                <td className="flex justify-center gap-1 px-4 py-2 text-xs text-white">
                  <div className="flex gap-1">
                    <span>
                      {new Date(item.JoinDate).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      })}
                    </span>
                    <span className="text-[#8C8C8C]">
                      {new Date(item.JoinDate).toLocaleTimeString("en-US", {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </span>
                  </div>
                </td>
                <td className="px-4 py-2 text-center text-white">
                  <div className="relative mx-auto flex justify-center text-center">
                    <button onClick={() => toggleDropdown(item._id)}>
                      <SlOptionsVertical />
                    </button>
                    {activeDropdown === item._id && (
                      <div className="absolute top-8 right-0 z-10 mt-2 w-32 rounded-md bg-[#171717] py-2 text-white shadow-xl">
                        <button
                          onClick={() => handleUserDelete(item._id)}
                          className="block w-full px-4 py-2 text-left text-sm hover:bg-gray-200"
                        >
                          Delete
                        </button>
                        <Link to={`/admin/userdetail/${item._id}`} key={index}>
                          <button className="block w-full px-4 py-2  text-left text-sm hover:bg-gray-200">
                            View Profile
                          </button>
                        </Link>
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div className="mt-4 flex items-center justify-between">
        <FaArrowLeft
          onClick={prevPage}
          className={`h-6   w-6 cursor-pointer ${
            currentPage === 1 ? "opacity-50" : "opacity-100"
          }`}
          style={{
            color: "#fff",
          }}
          disabled={currentPage === 1}
        />
        <span className="text-white">
          Page {currentPage} of {Math.ceil(data.length / itemsPerPage)}
        </span>
        <FaArrowRight
          onClick={nextPage}
          className={`h-6 w-6 cursor-pointer ${
            currentPage === Math.ceil(data.length / itemsPerPage)
              ? "opacity-50"
              : "opacity-100"
          }`}
          style={{
            color: "#fff",
          }}
          disabled={currentPage === Math.ceil(data.length / itemsPerPage)}
        />
      </div>
    </div>
  );
}

/* eslint-disable */
import React, { useEffect,useState } from "react";
import { Link, useLocation } from "react-router-dom";
import DashIcon from "components/icons/DashIcon";
import axios from 'axios';
// chakra imports

export function SidebarLinks(props) {
  // Chakra color mode
  const [admin, setAdmin] = useState([]);
  // const [layout, setLayout] = useState("");
  let location = useLocation();

  const { routes } = props;
  const layout = props.role


 
  const { user } = props;





  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  const createLinks = (routes) => {
 
    if(layout === 'user')
      {if(user.user.client){
        //show him dashboard only and profile transaction
        routes = routes.filter((route) => route.path === "dashboard" || route.path === "profile" || route.path === "transaction" || route.path === "marketplace")
      }else{
        //if he is not client then show him only portfolio and profile
        routes = routes.filter((route) => route.path === "portfolio" || route.path === "profile"|| route.path === "marketplace")
      }
    }
  


    return routes.map((route, index) => {
      if (
        route.layout === "/"+layout
      ) {
        return (
          <Link key={index} to={route.layout + "/" + route.path}>
            {/* <div className="relative mb-3 flex hover:cursor-pointer">
              <li
                className="my-[3px] flex cursor-pointer items-center px-8"
                key={index}
              >
                <span
                  className={`${
                    activeRoute(route.path) === true
                      ? "font-bold text-brand-500 dark:text-white"
                      : "font-medium text-gray-600"
                  }`}
                >
                  {route.icon ? route.icon : <DashIcon />}{" "}
                </span>
                <p
                  className={`leading-1 ml-4 flex ${
                    activeRoute(route.path) === true
                      ? "font-bold text-navy-700 dark:text-white"
                      : "font-medium text-gray-600"
                  }`}
                >
                  {route.name}
                </p>
              </li>
              {activeRoute(route.path) ? (
                <div class="absolute right-0 top-px h-9 w-1 rounded-lg bg-brand-500 dark:bg-brand-400" />
              ) : null}
            </div> */}
           <div className="relative mb-3 flex justify-center  hover:cursor-pointer">
            <li
          className={`
          ${activeRoute(route.path)
            ? "gradient-border bg-[#FFFFFF] px-3 py-2 flex cursor-pointer items-center"
            : "px-3 py-2 flex cursor-pointer"
          }`}
          style={{
            borderRadius: '40px',
            width: '200px',
          }}
          key={index}
        >
          <span
            className={`
              ${activeRoute(route.path)
                ? "font-bold text-brand-500 dark:text-black"
                : "font-medium text-[#010101]-600"
              }`}
            style={{
              color: '#fff' ,
              background: '#222222',
              borderRadius: '50%',
              padding: '5px',
        
            }}
          >
            {route.icon ? route.icon : <DashIcon />}{" "}
          </span>
          <p
            className={`leading-1 ml-4 flex ${
              activeRoute(route.path)
                ? "font-bold text-navy-700 dark:text-white"
                : "font-medium text-gray-600"
            }`}
            style={{
              color: activeRoute(route.path) ? '#000' : '#fff',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {route.name}
          </p>
        </li>

            </div>

          </Link>
        );
      }
    });
  };
  // BRAND
  return createLinks(routes);
}

export default SidebarLinks;

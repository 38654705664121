import React, { useState } from "react";
import axios from "axios";

export default function Security() {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleChangePassword = () => {
    if (newPassword !== confirmPassword) {
      alert("New password and confirm password do not match.");
      return;
    }
    const id = localStorage.getItem("id");
    const token = localStorage.getItem("token");

    console.log("Changing password...");
    //pass token a header authorization and id as params to the endpoint

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/user/change-password/${id}`,
        {
          oldPassword: oldPassword,
          newPassword: newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        alert("Password changed successfully");
      })
      .catch((err) => {
        console.log(err);
        alert("Failed to change password");
      });
  };

  return (
    <div className="flex flex-col gap-[34px] p-8">
      <h2>Change Your Password</h2>
      <div className="flex flex-col gap-2">
        <label htmlFor="Old Password" className="text-[15px] font-light">
          Old Password
        </label>
        <input
          type="password"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          placeholder="Enter your Old Password"
          className="bg-[#222222] py-3 px-5 outline-none"
        />
      </div>
      <div className="flex flex-col gap-2">
        <label htmlFor="New Password" className="text-[15px] font-light">
          New Password
        </label>
        <input
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          placeholder="Enter your New Password"
          className="bg-[#222222] py-3 px-5 outline-none"
        />
      </div>
      <div className="flex flex-col gap-2">
        <label htmlFor="Confirm Password" className="text-[15px] font-light">
          Confirm Password
        </label>
        <input
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder="Confirm your New Password"
          className="bg-[#222222] py-3 px-5 outline-none"
        />
      </div>
      <div className="flex h-fit w-full justify-end">
        <button
          onClick={handleChangePassword}
          className="bg-gd rounded-md px-9 py-2"
        >
          Save
        </button>
      </div>
    </div>
  );
}

import React from "react";
import avatar from "assets/img/avatars/admin.jpg";
import banner from "assets/img/profile/banner.png";
import Card from "components/card";
import { Link } from "react-router-dom";
import InputField from "components/fields/InputField";
import Investment from "views/admin/userprofile/investment/index";
import axios from "axios";
import { useParams } from "react-router";
import Transaction from "views/admin/userprofile/transaction/index";
import ProfitBook from "views/admin/userprofile/profitbooking/index";
import General from "./General";
import Tables from "./Tables";
import InvestmentTable from './InvestmentTable'
const Banner = (props) => {
  const { user, investamount,transaction,currentinvestment } = props;
  const [showModal, setShowModal] = React.useState(false);
  const [ModalData, setModalData] = React.useState("");
  const [withdrawal, setWithdrawal] = React.useState(0);
  console.log(currentinvestment)
  const { id } = useParams();
  React.useEffect(() => {
    setWithdrawal(user.withdrawal);
  }, [user]);
  console.log(user)
  const handlewithdrawal = () => {
    
    if(withdrawal<user.withdrawal){
      alert("Withdrawal cannot be less than the previous withdrawal")
      return;
    }
    if(withdrawal>7){
      alert("Withdrawal cannot be more than 7")
      return;
    }
    user.withdrawal = withdrawal;
    axios.post (process.env.REACT_APP_BASE_URL + `/user/updateuser/${id}`, 
      user
    )
      .then((res) => {
        console.log(res.data);
        setShowModal(false);
      })
      .catch((err) => {
        console.log(err);
      });
    
  }
  console.log(investamount);
  return (
    <Card extra={"items-center w-full h-full p-[16px] bg-cover"}>
      {/* Background and profile */}
      <div
        className="relative mt-1 flex h-32 w-full justify-center rounded-xl bg-cover"
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div className="absolute -bottom-12 flex h-[107px] w-[107px] items-center justify-center rounded-full border-[4px] border-white bg-pink-400 dark:!border-navy-700">
          <img className="h-full w-full rounded-full" src={avatar} alt="" />
        </div>
      </div>

      {/* Name and position */}
      <div className="mt-16 flex flex-col items-center">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          {user.username}
        </h4>
        <p className="text-base font-normal text-gray-600">{user.email}</p>
      </div>

      <div className="mt-4 flex h-full w-full items-center justify-center">
        <div className="flex h-1/2 w-1/2 flex-row !justify-between rounded-2xl px-6 py-3 shadow-2xl shadow-shadow-500  dark:shadow-none">
          <div className="flex flex-col items-center justify-center">
            <div className="flex items-center justify-center">
              <Link className="ml-1 text-sm font-normal text-gray-600"
                onClick={() => {
                  setModalData("Withdrawal");
                  setShowModal(true)
                }
                }
              >
                Add WithdrawaL
              </Link>
            </div>
          </div>

          <div className="h-11 w-px bg-gray-300 dark:bg-white/10" />
          <div className="flex flex-col items-center justify-center">
            <div className="flex items-center justify-center">
              <Link className="ml-1 text-sm font-normal text-gray-600"
                onClick={() => {
                  setModalData("Transaction");
                  setShowModal(true)
                }}
              >
                {" "}
                Add Transaction
              </Link>
            </div>
            <p className="mt-px text-xl font-bold text-navy-700 dark:text-white"></p>
          </div>
          <div className="h-11 w-px bg-gray-300 dark:bg-white/10" />

          <div className="flex flex-col items-center justify-center">
            <div className="flex items-center justify-center">
              <Link
                onClick={() => {
                  setModalData("Investment");
                  setShowModal(true)
                }}
                className="ml-1 text-sm font-normal text-gray-600"
              >
                Add Investment
              </Link>

             
            </div>
          </div>
          
        </div>

        {showModal ? (
          <>
            <div
              className="fixed inset-0 z-50 flex w-full
              items-center justify-center overflow-y-auto 
            overflow-x-hidden rounded-lg bg-[#171717] bg-opacity-70 outline-none focus:outline-none"
            >
              <div
                className="relative  my-6 mx-auto max-w-4xl"
                style={{
                  width: "100%",
                  borderRadius: "20px",
                  border: "1px solid #404040",
                  // background: "#252525 ",
                  backdropFilter: "blur(25px)",
                }}
              >
                {/*content*/}
                <div className=" relative flex w-full flex-col p-10">
                  {/*header*/}
                  <div className="flex items-start justify-between rounded-t border-b border-solid pt-5 pb-5">
                    <h3 className="text-3xl font-semibold">Add {ModalData && ModalData}</h3>
                    <button
                className="text-[#F9F9F9]-500 outline-nonemr-1 mb-1 flex flex-row gap-2 px-6 py-2 text-sm font-bold uppercase transition-all duration-150 ease-linear"
                type="button"
                onClick={() => setShowModal(false)}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 100 100"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    x1="10"
                    y1="10"
                    x2="90"
                    y2="90"
                    stroke="#F9F9F9"
                    stroke-width="10"
                  />
                  <line
                    x1="90"
                    y1="10"
                    x2="10"
                    y2="90"
                    stroke="#F9F9F9"
                    stroke-width="10"
                  />
                </svg>

              </button>
                  </div>
                  {/*body*/}
                  <div className="relative ">
                    {
                      ModalData === "Investment" ? <Investment /> : null
                    }
                    {
                      ModalData === "Withdrawal" ? 
                      <div >
                        <InputField
                          label="Amount"
                          type="number"
                          value={withdrawal}
                          onChange={(e) => setWithdrawal(e.target.value)}
                        />
                        <div className="flex justify-right items-center w-full">

                         <button
                onClick={() => handlewithdrawal()}
                    className=" text-white right-0 mt-5 bg-gd font-bold text-sm px-6 py-3 rounded  outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
                   
                    Add Withdrawal
                        </button>
                        </div>

                        </div>
                      : null
                    }
                    {
                      ModalData === "Transaction" ? <Transaction /> : null
                    }
                    {
                      ModalData === "Profitbook" ? <ProfitBook/>: null
                    }
                  </div>
                  {/*footer*/}
                </div>
              </div>
            </div>
          </>
        ) : null}

     
        
      </div>
      <div className="h-px w-full bg-gray-300 dark:bg-white/10" />
        <General user={user} />
    

      <div className="h-px w-full bg-gray-300 dark:bg-white/10" />
      <Tables
            columnsData={
              [
                {
                  Header: "NAME",
                  accessor: "name",
                },
                {
                  Header: "AMOUNT",
                  accessor: "amount",
                },
                {
                  Header: "TYPE",
                  accessor: "type",
                },
                {
                  Header: "PAYMENT METHOD",
                  accessor: "PaymentMethod",
                },
                {
                  Header: "DATE (MM/DD/YYYY)",
                  accessor: "date",
                }
              ]
            
            }
            tableData={transaction}
           
          />

      <div className="h-px w-full bg-gray-300 dark:bg-white/10"/>
      <InvestmentTable
                columnsData={[
                
                  {
                    Header: "COIN NAME",
                    accessor: "coinname",
                  },
                  {
                    Header: "INVESTMENT AMOUNT",
                    accessor: "investamount",
                  },
                  {
                    Header: "COIN PRICE",
                    accessor: "coinprice",
                  },
                  {
                    Header: "COIN QUANTITY",
                    accessor: "coinquantity",
                  },
                  {
                    Header: "DATE (MM/DD/YYYY)",
                    accessor: "investdate",
                  },
                  {
                    Header: "TRADE",
                    accessor: "_id",
                  },
                ]}

                tableData={currentinvestment}
          
              />
    </Card>
  );
};

export default Banner;

import React from "react";

export default function UserReviewCard({ review, loc, name, pic }) {
  return (
    <div className="mx-[.8rem] flex h-fit min-h-[24rem] min-w-[23rem] flex-col gap-[40px] rounded-xl border-[.5px] border-[#e2e8f022] bg-[#2222220e] p-[4rem] font-dm text-[14px] text-[#ACACAC]">
      <p className="h-fit w-full ">{review}</p>
      <div className="flex w-full items-center gap-3">
        <img
          className="h-[60px] w-[60px] rounded-full object-cover object-center"
          src={pic}
          alt="user image"
        />
        <div className="">
          <p className="text-white">{name}</p>
          <p>{loc}</p>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import "./loader2.css";

export default function Loader2() {
  return (
    <div class="three-body">
      <div class="three-body__dot"></div>
      <div class="three-body__dot"></div>
      <div class="three-body__dot"></div>
    </div>
  );
}

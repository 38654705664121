import React from "react";
import certi from "../Assets/certificate.png";
import cyan from "../../../assets/img/landings/glowCyan.png";

export default function Certificate({ gd }) {
  return (
    <div className=" relative flex w-full justify-center p-4">
      <div className="z-30 flex flex-col items-center justify-center gap-8 px-2 lg:w-[42rem] ">
        <div className="flex flex-col gap-5 text-center ">
          <h2 className="z-30 text-4xl font-bold">
            Who <span className="text-gd text-4xl font-bold">we are...</span>
          </h2>
          <p className="z-30 text-[#8C8C8C] ">
            At Titan Technologies, we are committed to guiding you through the
            exhilarating world of cryptocurrency investments. Our platform is
            designed to provide seamless, tailored investment solutions,
            ensuring you achieve maximum returns with minimal risk.{" "}
          </p>
        </div>
        <img draggable="false" src={certi} className="z-30 w-full" alt="" />
      </div>
      <img
        src={cyan}
        className="absolute top-[-14rem] right-[2rem] z-[-3] h-[35rem]"
        alt=""
      />
    </div>
  );
}

import React from "react";
import PieChart from "components/charts/PieChart";
import Card from "components/card";

const PieChartCard = (props) => {
  const { users } = props;

  // Get the current date and the date one month ago
  const currentDate = new Date();
  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(currentDate.getMonth() - 1);

  // Calculate the number of new users and total users
  const newUsers = users.filter(user => new Date(user.JoinDate) > oneMonthAgo).length;
  const totalUsers = users.length;

  // Prepare the data for the pie chart
  const pieChartData = [newUsers, totalUsers - newUsers];

  return (
    <Card extra="rounded-[20px]">
      <div className="flex flex-row border-b-2 border-gray-300 dark:border-white/10 justify-between px-3 pt-2">
        <div className="w-full p-3">
          <h4 className="text-lg font-bold text-navy-700 dark:text-white">
            Users
          </h4>
        </div>
      </div>

      <div className="flex mt-10 h-[320px] w-full items-center justify-center">
        <PieChart
          options={{
            labels: ["New Users", "Existing Users"],
            colors: ["#8B55D2", "#F143CA"],
            chart: {
              type: 'donut',
              width: '100%'
            },
            plotOptions: {
              pie: {
                expandOnClick: true,
                donut: {
                  size: '65%',
                  labels: {
                    show: true,
                    name: {
                      show: true,
                      fontSize: '22px',
                      color: '#373d3f'
                    },
                    value: {
                      show: true,
                      fontSize: '16px',
                      color: '#373d3f',
                      formatter: function (val) {
                        return val;
                      }
                    },
                    total: {
                      show: true,
                      showAlways: true,
                      label: 'Total',
                      fontSize: '16px',
                      fontFamily: 'Arial, sans-serif',
                      color: '#373d3f',
                      formatter: function (w) {
                        return w.globals.seriesTotals.reduce((a, b) => {
                          return a + b;
                        }, 0);
                      }
                    }
                  }
                }
              }
            },
            states: {
              hover: {
                filter: {
                  type: 'none',
                },
              },
            },
            legend: {
              show: true,
              position: 'bottom',
              labels: {
                colors: '#373d3f',

                fontSize: '12px',
               
              },
            },
            dataLabels: {
              enabled: false,
            },
            tooltip: {
              enabled: true,
              theme: 'dark',
              style: {
                fontSize: '12px',
                backgroundColor: '#973646',
              },
            },
            fill: {
              colors: ["#8B55D2", "#F143CA"],
            },
            stroke: {
              colors: ['#0D173C']
            },
          }}
          series={pieChartData}
          type="donut"
        />
      </div>
    </Card>
  );
};

export default PieChartCard;

import { border } from "@chakra-ui/system";
import Chart from "react-apexcharts";

const PieChart = (props) => {
  const { series, options } = props;

  return (
    <Chart
      
      options={options}
      type="donut"
      width="130%"
      height="130%"
      series={series}
    />
  );
};

export default PieChart;

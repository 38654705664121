import InputField from "components/fields/InputField";
import { FcGoogle } from "react-icons/fc";
import Checkbox from "components/checkbox";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Img from "./../../../assets/img/login/hero.jpg";
import Glowpurple from "./../../../assets/img/landings/glowblue.png";
import Logo from "./../../../assets/img/landings/Vector.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useNavigate();
  const [warning, setWarning] = useState(false);
  const [failed, setFailed] = useState(false);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const id = localStorage.getItem("id");
  
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/admin/getadmin`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const admin = res.data.find(
            (user) => user._id === id && user.role === "admin"
          );
  
          if (!admin) {
            setWarning(true);
            setTimeout(() => {
              setWarning(false);
              history("/");
            }, 2000);
          } else {
            setSuccess(true);
            toast.success("You are Already Login as Admin");
            setTimeout(() => {
              history("/admin/dashboard");
            }, 1000);
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 403) {
            localStorage.clear();
            toast.error("Session expired. Please log in again.");
            history("/auth/admin"); // Redirect to the login page
          } else {
            console.error(error);
            toast.error("An error occurred while fetching admin data.");
            history("/");
          }
        });
    }
  }, [history]);
  

  const handlesubmit = (e) => {
    const data = {
      email: email,
      password: password,
      username: "admin",
      role: "admin",
    };

    e.preventDefault();

    axios
      .post(process.env.REACT_APP_BASE_URL + "/auth/admin/login", data)
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("id", res.data.admin._id);
          // setSuccess(true);
          // setMessage('Login Successfully');
          toast.success("Login Successfully");
          setTimeout(() => {
            // setSuccess(false);
            history("/admin/dashboard");
          }, 1000);
        }
      })
      .catch((err) => {
        // setFailed(true);
        setTimeout(() => {
          // setFailed(false);
        }, 1000);
        // setMessage("Invalid Email or Password Please Try Again");
        toast.warn("Invalid Email or Password Please Try Again");
      });
  };

  return (
    <>
      {warning &&
        toast.warn("   You are Already Login as User Please Logout First")}
      {/* {warning && (
        <div class="absolute  mt-10 ml-10 start-0">
          <div
            class="max-w-xs rounded-xl border border-gray-200 bg-white shadow-lg dark:border-gray-700 dark:bg-gray-800"
            role="alert"
          >
            <div class="flex p-4">
              <div class="flex-shrink-0">
                <svg
                  class="size-4 mt-0.5 flex-shrink-0 text-yellow-500"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                </svg>
              </div>
              <div class="ms-3">
                <p class="text-sm text-gray-700 dark:text-gray-400">
                  You are Already Login as User Please Logout First
                </p>
              </div>
            </div>
          </div>
        </div>
      )} */}
      {/* {failed && (
        <div class="absolute  mt-10 ml-10 start-0">
          <div
            class="max-w-xs rounded-xl border border-gray-200 bg-white shadow-lg dark:border-gray-700 dark:bg-gray-800"
            role="alert"
          >
            <div class="flex p-4">
              <div class="flex-shrink-0">
                <svg
                  class="size-4 mt-0.5 flex-shrink-0 text-red-500"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 0a8 8 0 0 0 0 16A8 8 0 0 0 8 0zM7 5a1 1 0 0 1 2 0v5a1 1 0 1 1-2 0V5zm1 7a1 1 0 0 1-2 0 1 1 0 0 1 2 0z"
                  />
                </svg>
              </div>
              <div class="ms-3">
                <p class="text-sm text-gray-700 dark:text-gray-400">
                  {message}
                </p>
              </div>
            </div>
          </div>
        </div>
      )} */}
      {success && (
        <div class="absolute  mt-10 ml-10 start-0">
          <div
            class="max-w-xs rounded-xl border border-gray-200 bg-white shadow-lg dark:border-gray-700 dark:bg-gray-800"
            role="alert"
          >
            <div class="flex p-4">
              <div class="flex-shrink-0">
                <svg
                  class="size-4 mt-0.5 flex-shrink-0 text-green-500"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 0a8 8 0 0 0 0 16A8 8 0 0 0 8 0zM7 5a1 1 0 0 1 2 0v5a1 1 0 1 1-2 0V5zm1 7a1 1 0 0 1-2 0 1 1 0 0 1 2 0z"
                  />
                </svg>
              </div>
              <div class="ms-3">
                <p class="text-sm text-gray-700 dark:text-gray-400">
                  {message}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      <section
        className={`
          ${warning && "pointer-events-none"}
          main-sec relative flex h-screen w-full flex-col items-center md:flex-row`}
        // style={{
        //   background:
        //     "linear-gradient(to right bottom, rgba(0,0,0,0.9), rgba(0,0,0,0.9))",
        //   color: "#fff",
        // }}
      >
        <img
          src={Glowpurple}
          alt=""
          draggable="false"
          className="absolute h-1/2 w-1/2  "
          style={{
            zIndex: 20,
            left: "0",
            top: "50",
          }}
        />
        <img
          src={Glowpurple}
          alt=""
          draggable="false"
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: "0",
            left: "50%",
            zIndex: -99,
          }}
          className="absolute bottom-0 right-0 w-full"
        />

        <div className="relative hidden h-full w-full p-16 lg:block">
          <img src={Logo} alt="" className="ml-10" />
          {/* <img
            src={Dash}
            alt=""
            className="h-full w-full object-contain"
            style={{
              zIndex: 1,
            }}
          /> */}

         
          {/* <img
            src={Glowpurple}
            alt=""
            draggable="false"
            style={{
              width: "150px",
              height: "150px",
              position: "absolute",
              top: "10%",
              left: "10%",
              zIndex: 1,
            }}
            className="absolute top-10 left-10"
          />
          <img
            src={Glowpurple}
            alt=""
            draggable="false"
            style={{
              width: "200px",
              height: "200px",
              position: "absolute",
              top: "20%",
              right: "15%",
              zIndex: 1,
            }}
            className="right-15 absolute top-20"
          />
          <img
            src={Glowpurple}
            alt=""
            draggable="false"
            style={{
              width: "100px",
              height: "100px",
              position: "absolute",
              bottom: "20%",
              left: "20%",
              zIndex: 1,
            }}
            className="absolute bottom-20 left-20"
          /> */}
        </div>

        <div
          className="relative !z-50  flex h-screen w-full items-center justify-center px-6 md:mx-auto md:w-1/2 md:max-w-md lg:max-w-full lg:px-16 xl:w-3/5 xl:px-12"
          style={{
            // background: "rgba( 19, 22, 19, 0.2)",
            boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
            backdropFilter: "blur( 25px )",
            WebkitBackdropFilter: "blur( 25px )",
            borderRadius: "10px",
            border: "1px solid rgba( 255, 255, 255, 0.18 )",
            zIndex: 2,
          }}
        >
          <div className="w-full rounded-lg  p-8 backdrop-blur-lg">
            <h1 className="mt-12 text-3xl font-bold leading-tight text-white md:text-4xl">
              Login
            </h1>
            <form className="mt-6 flex flex-col  !text-[#ACACAC]">
              <div className="flex flex-col justify-start gap-3">
                <label htmlFor="email">Email</label>
                <input
                  extra="mb-3"
                  placeholder="Enter your Email"
                  id="email"
                  className="rounded-md border-[.7px] border-[#acacac] bg-[#ffffff00] px-4 py-3 outline-none placeholder:text-[#acacac] "
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mt-4 flex flex-col justify-start gap-3">
                <label htmlFor="email">Password</label>
                <input
                  extra="mb-3"
                  placeholder="Enter your password"
                  id="email"
                  className="rounded-md border-[.7px] border-[#acacac] bg-[#ffffff00] px-4 py-3 outline-none placeholder:text-[#acacac] "
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <div className="mt-2 text-right">
                <Link
                  to="/auth/user/forgotpassword"
                  className="text-gd text-sm font-semibold text-gray-300 hover:underline"
                >
                  Forgot password?
                </Link>
              </div>
              <button
                type="submit"
                className="mt-6 block w-full rounded-lg bg-gradient-to-r from-purple-400 to-pink-500 px-4 py-3 font-semibold text-white hover:from-purple-500 hover:to-pink-600 focus:from-purple-500 focus:to-pink-600"
                onClick={handlesubmit}
              >
                Login
              </button>
            </form>
            <hr className="my-6 w-full border-gray-300" />
            <button
              type="button"
              className="flex w-full items-center justify-center rounded-lg border  px-4 py-3 font-semibold text-white"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                viewBox="0 0 48 48"
              >
                <path
                  fill="#4285F4"
                  d="M48 24c0-1.31-.1-2.57-.27-3.79H24v7.58h13.65c-.59 3.11-2.47 5.74-5.28 7.42v6.1h8.51c4.98-4.59 7.87-11.38 7.87-19.31z"
                />
                <path
                  fill="#34A853"
                  d="M24 48c6.48 0 11.92-2.15 15.89-5.81l-8.51-6.1c-2.37 1.6-5.37 2.55-7.38 2.55-5.61 0-10.37-3.8-12.07-8.87H3.38v5.66C7.36 42.65 15.09 48 24 48z"
                />
                <path
                  fill="#FBBC05"
                  d="M11.93 28.37c-.55-1.31-.87-2.72-.87-4.37s.32-3.06.87-4.37V14.97H3.38C1.23 19.06 0 23.88 0 28.37s1.23 9.31 3.38 13.4l8.55-6.1z"
                />
                <path
                  fill="#EA4335"
                  d="M24 9.52c3.26 0 6.17 1.11 8.48 2.91l6.38-6.38C34.02 2.77 29.32 0 24 0 15.09 0 7.36 5.35 3.38 13.4l8.55 6.1C13.63 13.32 18.39 9.52 24 9.52z"
                />
              </svg>
              <span className="ml-4">Continue with Google</span>
            </button>
            <p className="mt-8 text-gray-300">
              Don’t have an account?{" "}
              <Link
                to="/auth/user/signup"
                className="font-semibold text-[#F78DA7] hover:underline"
              >
                Register
              </Link>
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

import React, { useMemo, useState } from "react";
import moment from "moment";
import { MdArrowDropUp } from "react-icons/md";
import Card from "components/card";
import BarChart from "components/charts/BarChart";

const DailyTraffic = (props) => {
  const { totalprofit, profitbook, Switch } = props;

  const [btn, setBtn] = useState(0);

  // Group profit by month
  const monthlyProfits = useMemo(() => {
    const profitsByMonth = {};

    profitbook.forEach(({ profitdate, profit }) => {
      const month = moment(profitdate).format("YYYY-MM");
      if (!profitsByMonth[month]) {
        profitsByMonth[month] = 0;
      }
      profitsByMonth[month] += profit;
    });

    return Object.entries(profitsByMonth).map(([month, profit]) => ({
      month,
      profit,
    }));
  }, [profitbook]);

  // Prepare data for the bar chart
  const barChartDataMonthlyProfit = {
    labels: monthlyProfits.map(({ month }) => month),
    datasets: [
      {
        label: "Monthly Profit",
        data: monthlyProfits.map(({ profit }) => profit),
        backgroundColor: "#4c51bf",
      },
    ],
  };

  // Chart options
  const barChartOptionsMonthlyProfit = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: undefined,
        backgroundColor: "#000000",
      },
      onDatasetHover: {
        style: {
          fontSize: "12px",
          fontFamily: undefined,
        },
      },
      theme: "dark",
    },
    xaxis: {
      categories: monthlyProfits.map(({ month }) => month),
      show: false,
      labels: {
        show: true,
        style: {
          colors: "#A3AED0",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
      color: "black",
      labels: {
        show: true,
        style: {
          colors: "#CBD5E0",
          fontSize: "14px",
        },
      },
    },
    grid: {
      show: false,
      strokeDashArray: 5,
      yaxis: {
        lines: {
          show: true,
        },
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        type: "vertical",
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        colorStops: [
          [
            {
              offset: 0,
              color: "#4318FF",
              opacity: 1,
            },
            {
              offset: 100,
              color: "rgba(67, 24, 255, 1)",
              opacity: 0.28,
            },
          ],
        ],
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: "40px",
      },
    },
  };

  return (
    <Card extra="pb-7 ">
      <div className="flex flex-row justify-between border-b-2 border-gray-300 px-3 pt-2 dark:border-white/10">
        <div className="flex w-full items-center justify-between p-3">
          <h4 className="text-lg font-bold text-navy-700 dark:text-white">
            Profits
          </h4>
          {Switch && (
            <div className="rounded-lg bg-[#252525] py-[3px] px-[2px] text-[12px]">
              <button
                className={`w-[3.5rem] rounded-md ${
                  btn == 0 ? "text-gd bg-[#404040]" : ""
                } p-[2px]`}
                // onClick={setBtn(0)}
              >
                Monthly
              </button>
              <button
                className={`w-[3.5rem] rounded-md  ${
                  btn == 1 ? "text-gd bg-[#404040]" : ""
                } p-[2px]`}
                // onClick={setBtn(1)}
              >
                Week
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-row justify-between p-5">
        <div className="ml-1 pt-2">
          <p className="text-[34px] font-bold text-navy-700 dark:text-white">
            {"$" + totalprofit}
          </p>
        </div>
        <div className="mt-2 flex items-start">
          <div className="flex items-center text-sm text-green-500">
            <MdArrowDropUp className="h-5 w-5" />
            <p className="font-bold"> 0% </p>
          </div>
        </div>
      </div>
      <div className="h-[300px] w-full pt-10 pb-0">
        <BarChart
          chartData={barChartDataMonthlyProfit}
          chartOptions={barChartOptionsMonthlyProfit}
        />
      </div>
    </Card>
  );
};

export default DailyTraffic;

import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/tables";
import UsersDetail from "views/admin/userprofile";
import AddTransaction from "views/admin/userprofile/transaction/index.js";
import AddInvestment from "views/admin/userprofile/investment/index.jsx";

//user imports
import UserDashboard from "views/user/default";
// import UserMarketplace from "views/user/marketplace";
// import UserProfile from "views/user/profile";
import UserTables from "views/user/tables";
import Market from "views/admin/market";
import Marketplace from "views/user/marketplace2/index";
// Auth Imports

//UserDashboard Imports
import UserProfile from "views/user/UserProfile";
import Transaction from "views/user/transactions";
import Transaction2 from "views/admin/transactions";

// Icon Imports
import {
  MdHome,
  MdOutlineShoppingCart,
  MdBarChart,
  MdPerson,
  MdLock,
} from "react-icons/md";
import Portfolio from "views/user/portfolio";
import sidebarUser from "./assets/img/sidebar/profile_img.png";
import wallet from "./assets/img/sidebar/wallet.png";
import trade from "./assets/img/sidebar/trade.png";
import activity from "./assets/img/sidebar/activity.png";
import dashboard from "./assets/img/sidebar/dashboard.png";
import TransactionAdmin from "views/admin/transactions";

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "dashboard",
    icon: <img src={dashboard} className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  // {
  //   name: "NFT Marketplace",
  //   layout: "/admin",
  //   path: "nft-marketplace",
  //   icon: <MdOutlineShoppingCart className="h-6 w-6" />,
  //   component: <NFTMarketplace />,
  //   secondary: true,
  // },
  // {
  //   name: "History",
  //   layout: "/admin",
  //   icon: <img src={wallet} className="h-6 w-6" />,
  //   path: "data-tables",
  //   component: <DataTables />,
  // },
  // {
  //   name: "Profile",
  //   layout: "/admin",
  //   path: "profile",
  //   icon: <MdPerson className="h-6 w-6" />,
  //   component: <Profile />,
  // },
  {
    name: "Users",
    layout: "/admin",
    path: "user-detail",
    icon: <img src={sidebarUser} className="h-6 w-6" />,
    component: <UsersDetail />,
  },

  {
    name: "Dashboard",
    layout: "/user",
    path: "dashboard",
    icon: <img src={dashboard} className="h-6 w-6" />,
    component: <UserDashboard />,
  },

  // {
  //   name: "Data Tables",
  //   layout: "/user",
  //   icon: <MdBarChart className="h-6 w-6" />,
  //   path: "data-tables",
  //   component: <UserTables />,
  // },
  
  {
    name: "Portfolio",
    layout: "/user",
    path: "portfolio",
    icon: <img src={activity} className="h-6 w-6" />,
    component: <Portfolio />,
  },
  {
    name: "Transactions",
    layout: "/user",
    path: "transaction",
    icon: <img src={wallet} className="h-6 w-6" />,
    component: <Transaction />,
  },
  {
    name: "History",
    layout: "/admin",
    path: "transaction",
    icon: <img src={wallet} className="h-6 w-6" />,
    component: <TransactionAdmin />,
  },

  {
    name: "Live Market",
    layout: "/user",
    path: "marketplace",
    icon: <img src={trade} className="h-6 w-6 " />,
    component: <Marketplace />,
  },
  {
    name: "Live Market",
    layout: "/admin",
    path: "marketplace",
    icon: <img src={trade} className="h-6 w-6 " />,
    component: <Marketplace />,
  },
  {
    name: "Profile",
    layout: "/user",
    path: "profile",
    icon: <img src={sidebarUser} className="h-6 w-6" />,
    component: <UserProfile />,
  },
];
export default routes;

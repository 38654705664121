import React, { useState } from "react";
import UserReviewCard from "../components/UserReviewCard";
import data from "../Assets/UserReview.json";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import glowCyan from "../Assets/glowCyan.png";
import glowOrange from "../../../assets/img/landings/gloworange.png";
import glowpink from "../../../assets/img/landings/glowpink.png";
import "../../../assets/css/GlowMoving.css";
import btnright from "../Assets/Buttonright.png";
import btnleft from "../Assets/Buttonleft.png";

export default function SliderUsers() {
  const [current, setCurrent] = useState(0);
  const cardWidth = 395; // Adjusted width to match card + margin/padding
  let limit = 3;

  const next = () => {
    if (current + 3 < data.user.length) {
      setCurrent(current + 1);
    }
  };

  const prev = () => {
    if (current > 0) {
      setCurrent(current - 1);
    }
  };

  return (
    <section
      id="Testimonials"
      className=" relative my-7  mt-5 flex flex-col gap-12"
    >
      <div className="flex flex-col items-center gap-2">
        <h2 className="text-[2.5rem] font-extrabold">
          Hear from our{" "}
          <span
            className=""
            style={{
              background:
                "linear-gradient(to left, #9b51e0, #c655cc, #e162ba, #f076ad, #f78da7)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Client
          </span>
        </h2>
        <p className="w-[60%] text-center text-sm text-[#ACACAC]">
          We bring the collective strength of the firm to benefit our companies,
          communities and clients.
        </p>
      </div>
      <div className="container z-30 flex flex-col items-center gap-12">
        <div className={`relative w-[395px] overflow-hidden xl:w-[1170px]`}>
          <div
            className="slider flex   transition-transform duration-500 ease-in-out"
            style={{ transform: `translateX(-${current * cardWidth}px)` }}
          >
            {data.user.map((item, id) => (
              // <div key={id} className="mx-2 w-[370px] flex-shrink-0">
              <UserReviewCard
                className="mx-2"
                loc={item.loc}
                review={item.review}
                name={item.name}
                pic={item.pic}
              />
              //</div>
            ))}
          </div>
        </div>
        <div className="flex items-center justify-center gap-5">
          <img
            src={btnleft}
            className={`h-12 rounded-full border-[#222222] bg-[#171717] transition-all   duration-150 active:h-10 ${
              current === 0
                ? "opacity-20 hover:cursor-not-allowed"
                : "hover:cursor-pointer"
            } `}
            onClick={prev}
          />
          <img
            src={btnright}
            className={`h-12 rounded-full border-[#222222] bg-[#171717] transition-all   duration-150 active:h-10 ${
              current + 3 >= data.user.length
                ? "opacity-20 hover:cursor-not-allowed"
                : "hover:cursor-pointer"
            }`}
            onClick={next}
          />
        </div>
      </div>
      <img
        src={glowOrange}
        draggable="false"
        className="nonselectable glowMovingLB absolute left-[-600px] top-[-700px] !z-0 h-[400px] opacity-70 lg:h-[1500px] lg:w-[1350px]"
      />
    </section>
  );
}

import React, { useState, useEffect } from "react";
import data from "../Assets/Article.json";
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";
import { GoDotFill } from "react-icons/go";
import { MdOutlineArrowOutward } from "react-icons/md";
import glowCyan from "../Assets/glowCyan.png";
import glowOrange from "../../../assets/img/landings/gloworange.png";
import glowpink from "../../../assets/img/landings/glowpink.png";
import axios from "axios";
import "../../../assets/css/GlowMoving.css";
import logo from "../Assets/Icon.svg";

export default function SliderArticle() {
  const [slide, setSlide] = useState(0);
  // const [data, setData] = useState({ articles: [] });
  const [articles, setArticles] = useState([]);
  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 3000);

    return () => clearInterval(interval);
  }, [slide]);

  useEffect(() => {
  //   axios
  //     .get(
  //       "https://cryptopanic.com/api/v1/posts/?auth_token=8aaa04e0dd2eeb966b2230d4a07d31cf6ebfd6b1",)
  //     .then((res) => {
  //       console.log(res);
  //       // setArticles(res.data.results);
  //     });
  // }, []);
  //add cors header to the api
  axios
    .get(
     process.env.REACT_APP_BASE_URL + "/articles"
    )
    .then((res) => {
      console.log(res);
      setArticles(res.data.results);
    });
}, []);

  const handlePrev = () => {
    if (slide > 0) {
      setSlide(slide - 1);
    } else {
      setSlide(data.articles.length - 1);
    }
  };

  const handleNext = () => {
    if (slide < data.articles.length - 1) {
      setSlide(slide + 1);
    } else {
      setSlide(0);
    }
  };

  return (
    <section className="relative flex w-full flex-col items-center xl:p-12">
      <h2
        className="flex flex-col text-center text-4xl font-bold"
        style={{
          fontFamily: "DM Sans",
          fontWeight: "700",
          // letterSpacing: "0.1em",
        }}
      >
        Get insights from our{" "}
        <span
          style={{
            background:
              "linear-gradient(to left, #9b51e0, #c655cc, #e162ba, #f076ad, #f78da7)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Expert Articles
        </span>
      </h2>
      <div className="relative mt-6 flex h-fit w-[fit] items-center overflow-auto px-14 xl:h-[40rem] ">
        <button
          onClick={handlePrev}
          disabled={slide === 0}
          className={`absolute left-0 z-10 hidden cursor-pointer text-3xl opacity-30 xl:block ${
            slide === 0 ? "cursor-not-allowed opacity-20" : ""
          }`}
        >
          <FaArrowCircleLeft />
        </button>
        <div className=" relative z-30 flex h-fit w-fit justify-center overflow-x-hidden  rounded-[20px] pb-14 xl:items-center">
          {articles &&
            articles.map((item, index) => (
              <>
                <div
                  key={index}
                  className={` flex min-w-fit  transform flex-col-reverse items-center gap-8 rounded-xl border-[.5px] border-[#e2e8f022]  bg-[#2222223a] p-5 transition-transform duration-500 ease-in-out xl:flex-row xl:p-[4rem] ${
                    slide === index
                      ? "translate-x-0"
                      : "hidden translate-x-full"
                  }`}
                >
                  <div className="flex w-[350px]  flex-1 flex-col items-center gap-8 p-4 xl:w-fit xl:items-start">
                    <h2 className="text-2xl font-semibold xl:w-full xl:text-center">
                      {item.title}
                    </h2>
                    <p className="w-full max-w-fit whitespace-normal text-center text-[.9rem] font-light text-[#ACACAC] xl:text-left">
                      {item.description}
                    </p>
                    <div className="">
                      <a href={item.url} target="_blank" rel="noreferrer">
                        <button
                          style={{
                            background:
                              "linear-gradient(to left, #9b51e0, #c655cc, #e162ba, #f076ad, #f78da7)",
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                          }}
                          className=" flex w-fit items-center gap-2"
                        >
                          Read More <img src={logo} alt="" />
                        </button>
                      </a>
                    </div>
                  </div>
                  {item.image_url && (
                    <img
                      src={item.image_url}
                      className="h-[17rem] w-[25rem] flex-1 rounded-lg object-cover"
                      alt={item.title}
                    />
                  )}
                </div>
                <div className="absolute bottom-0 flex items-center gap-1">
                  {articles.map((item, inx) => {
                    return (
                      // <GoDotFill
                      //   key={inx}
                      // onClick={() => {
                      //   setSlide(inx);
                      // }}
                      //   className={` text-[25px] hover:cursor-pointer  ${
                      //     slide == inx
                      //       ? "text-[1.2rem] text-red-400"
                      //       : "text-white opacity-5"
                      //   }`}
                      // />
                      <div
                        key={inx}
                        onClick={() => {
                          setSlide(inx);
                        }}
                        className={`h-4 w-4 rounded-full hover:cursor-pointer ${
                          slide == inx
                            ? "bg-gradient-to-r from-[#F78DA7] to-[#9B51E0]"
                            : "bg-[#252525]"
                        } `}
                      ></div>
                    );
                  })}
                </div>
              </>
            ))}
        </div>
        <button
          onClick={handleNext}
          disabled={slide === data.articles.length - 1}
          className={`absolute right-0 z-10 hidden cursor-pointer text-3xl opacity-30 xl:block ${
            slide === data.articles.length - 1
              ? "cursor-not-allowed opacity-20"
              : ""
          }`}
        >
          <FaArrowCircleRight className="" />
        </button>
      </div>
      <img
        src={glowCyan}
        draggable="false"
        className="nonselectable glowMovingTL absolute left-[-600px] bottom-[-400px] !z-0 h-[1000px] w-[1350px]"
      />
    </section>
  );
}

import React, { useEffect, useState } from "react";
import User from "../Assests/a.png"
import axios from "axios";

export default function YourProfile(props) {
  const { user } = props;
  const [profilePicture, setProfilePicture] = useState(User);
  const [phone, setPhone] = useState(user.phone);

  const id = localStorage.getItem('id');

  useEffect(() => {
    if(user.photo) setProfilePicture(user.photo);
    setPhone(user.phone);
  }, [user]);

  const handleChangePicture = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET);

      try {
        const res = await axios.post(`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`, formData);
        const imageUrl = res.data.secure_url;

        setProfilePicture(imageUrl);
        user.photo = imageUrl;

        await axios.post(`${process.env.REACT_APP_BASE_URL}/user/updateuser/${id}`, user).then((res) => {
          console.log(res);
          alert("Profile picture updated successfully.");
        }).catch((err) => {
          console.log(err);
        }
        );
    
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleDeletePicture = () => {
    setProfilePicture(User); // Reset to default profile picture
    user.photo = null;
    axios.post(`${process.env.REACT_APP_BASE_URL}/user/updateuser/${id}`, user).then((res) => {
      console.log(res);
      alert("Profile picture deleted successfully.");
    }).catch((err) => {
      console.log(err);
    });
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handleSave = async () => {
    user.phone = phone;
    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}/user/updateuser/${id}`, user);
      console.log("Profile updated successfully.");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="flex flex-col gap-[34px] p-8">
      <div>
        <div className="my-5 flex items-center justify-start gap-6">
          <img
            src={profilePicture}
            className="h-20 w-20 rounded-full object-cover object-center"
            alt="Profile"
          />
          <input
            type="file"
            accept="image/*"
            onChange={handleChangePicture}
            className="hidden"
            id="upload-input"
          />
          <button
            onClick={() => document.getElementById("upload-input").click()}
            className="rounded-md bg-gradient-to-r from-[#F78DA7] to-[#9B51E0] py-2 px-6 text-[9px] text-white lg:text-[14px]"
          >
            Change Picture
          </button>
          <button
            onClick={handleDeletePicture}
            className="rounded-md bg-[#222222] py-2 px-6 text-[9px] text-[#E0042C] lg:text-[14px]"
          >
            Delete Picture
          </button>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <label htmlFor="email" className="text-[15px] font-light">
          Email Address
        </label>
        <input
          type="email"
          name="email"
          value={user.email}
          id="email"
          disabled
          placeholder="Enter your email"
          className="bg-[#222222] py-3 px-5 outline-none"
        />
      </div>
      <div className="flex flex-col gap-2">
        <label htmlFor="phone" className="text-[15px] font-light">
          Phone Number
        </label>
        <input
          type="phone"
          name="phone"
          value={phone}
          onChange={handlePhoneChange}
          placeholder="Enter your phone number"
          id="phone"
          className="bg-[#222222] py-3 px-5 outline-none"
        />
      </div>
      <div className="flex h-fit w-full justify-end">
        <button
          onClick={handleSave}
          className="rounded-md bg-gradient-to-r from-[#F78DA7] to-[#9B51E0] px-9 py-2"
        >
          Save
        </button>
      </div>
    </div>
  );
}

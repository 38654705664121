import React, { useState } from "react";
import { BsArrowLeftCircle, BsArrowRightCircle } from "react-icons/bs";
import deposit from "../Assests/Deposit.svg";
import payout from "../Assests/Payout.svg";
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";
import Loader from "components/loader/Loader";

export default function Table({ columnData, cellData }) {
  const [currentPage, setCurrentPage] = useState(1);

  console.log(cellData, columnData)
  const rowsPerPage = 10;

  const totalPages = Math.ceil(cellData.length / rowsPerPage);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const currentData = cellData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const tableStyle = {
    width: "100%",
  };
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert('Copied to clipboard!');
    }).catch(err => {
      console.error('Could not copy text: ', err);
    });
  };

  const cellStyle = {
    padding: "8px",
  };
  if (!cellData || !columnData) {
    return (
      <div className="scale-[.6]">
        <Loader />
      </div>
    );
  }
  return (
    <>
      <table style={tableStyle} className="!rounded-xl text-[14px]">
        <thead className="rounded-xl text-left">
          <tr>
            {columnData.map((data, index) => (
              <th
                className="truncate px-4 font-extralight text-cyan-300 lg:!px-10"
                key={index}
                style={cellStyle}
              >
                {data.Header}
              </th>
            ))}
          </tr>
        </thead>

        <tbody className="w-full rounded-b-xl pb-5 text-left">
          {currentData.length < 1 ? (
            <tr className="!my-6 w-full">
              <td className="!w-full text-center" colSpan={columnData.length}>
                <h1>No Transaction</h1>
              </td>
            </tr>
          ) : (
            currentData.map((item, index) => (
              <tr key={index} className="!my-6">
                <td
                  style={cellStyle}
                  className="max-w-[150px] truncate text-left uppercase"
                >
                  <span>{item.username ? item.username : "Not Available"}</span>
                </td>
                <td
                  style={cellStyle}
                  className="max-w-[150px] truncate text-left cursor-pointer"
                  onClick={() => copyToClipboard(item._id)}
                >
                  <span>{item._id} </span>
                </td>

                <td style={cellStyle} className="p-auto text-left">
                  {item.type === "RECEIVE" ? (
                    <img
                      src={deposit}
                      alt="Deposit"
                      className="mx-auto h-6 w-6"
                    />
                  ) : (
                    <img
                      src={payout}
                      alt="Payout"
                      className="mx-auto h-6 w-6"
                    />
                  )}
                </td>
                <td style={cellStyle} className="text-left text-center uppercase">
                  <span>{item.PaymentMethod}</span>
                </td>
                <td
                  style={cellStyle}
                  className={`text-left text-center md:flex flex-col ${
                    item.type === "RECEIVE" ? "text-green-500" : "text-red-500"
                  }`}
                >
                  {item.type === "SEND" ? "-" : "+"}₹{item.amount}
                </td>
                <td style={cellStyle} className="text-left text-center">
                  {item.sentTo ? item.sentTo : "NA"}
                </td>
                <td style={cellStyle} className="text-left">
                  <div className="mx-auto w-full">
                    {new Date(item.date).toLocaleDateString("en-US", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })}
                    ,{" "}
                    <span className="text-[#8C8C8C]">
                      {new Date(item.date).toLocaleTimeString("en-US", {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      })}
                    </span>
                  </div>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      <div className="mt-4 flex w-full items-center justify-between gap-4 px-16">
        <FaArrowCircleLeft
          className={`cursor-pointer text-[24px] ${
            currentPage === 1 ? "opacity-50" : "opacity-100"
          }`}
          onClick={() => handlePageChange(currentPage - 1)}
        />
        <div className="flex cursor-pointer gap-2">
          {[...Array(totalPages)].map((_, index) => (
            <div
              key={index}
              className={`flex items-center justify-center rounded-full border-[.4px] border-[#ffffff]/20 p-[.5px]`}
              onClick={() => handlePageChange(index + 1)}
            >
              <div
                className={`flex h-8 w-8 items-center justify-center rounded-full ${
                  currentPage !== index + 1
                    ? "bg-[#ffffff00]"
                    : "bg-gradient-to-r from-[#F78DA7] to-[#9B51E0]"
                } text-sm`}
              >
                <span
                  className={` block ${
                    currentPage !== index + 1 ? "text-gd" : "text-white"
                  } `}
                >
                  {index + 1}
                </span>
              </div>
            </div>
          ))}
        </div>
        <FaArrowCircleRight
          className={`cursor-pointer text-[24px] ${
            currentPage === totalPages ? "opacity-50" : "opacity-100"
          }`}
          onClick={() => handlePageChange(currentPage + 1)}
        />
      </div>
    </>
  );
}

import React from "react";
import Chart from "react-apexcharts";

const LineChart = ({ data = [] }) => { // Ensure data has a default value
  const options = {
    chart: {
      height: 350,
      type: 'area',
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      type: 'datetime',
    },
    tooltip: {
      theme: 'dark',
      x: {
        format: 'dd/MM/yy HH:mm',
      },
      style: {
        fontSize: '12px',
        color: '#fff',
      },
      marker: {
        show: true,
      },
      y: {
        formatter: function (value) {
          return value.toFixed(2);
        },
      },
    },
    grid: {
      yaxis: {
        lines: {
          show: true,
          style: {
            strokeDashArray: 5, // Dotted line
          },
        },
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'center',
      labels: {
        colors: '#fff',
      },
    },
  };

  const series = data.map(coin => ({
    name: coin.name,
    data: coin.data.map(point => ({
      x: new Date(point.date).getTime(), // Ensure the date is in milliseconds
      y: parseFloat(point.pnl.toFixed(2)), // Ensure PNL is a number
    })),
  }));

  return (
    <Chart
      options={options}
      series={series}
      type="area"
      height={350}
    />
  );
};

export default LineChart;

import React from "react";
import Profile from "./Sections/Profile";


export default function UserProfile() {
  return (
    <div>
      <Profile />
      {/* <UserTableContainer /> */}
    </div>
  );
}

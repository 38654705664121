import React from "react";
import cryptoFT from "../../../assets/img/landings/crytoFT-removebg-preview.png";
import binance from "../../../assets/img/landings/binance-removebg-preview.png";
import emiratesNBD from "../../../assets/img/landings/emiratesNBD-removebg-preview.png";
import coinDCX from "../../../assets/img/landings/coinDCX-removebg-preview.png";
import "../Assets/RunningLogos.css";

export default function InvestedBy() {
  return (
    <section className="z-30 my-20">
      <div className="flex flex-col gap-32  ">
        <div className="">
          <h2 className="mb-8 flex w-full justify-center gap-3 text-center text-[2.5rem] font-semibold md:text-[2.5rem]">
            Our
            <span className="text-gd text-[2.5rem] font-semibold md:text-[2.5rem]">
              Super{" "}
            </span>{" "}
            Clients
          </h2>
        </div>
        <div className="slider-container-faq relative mb-40 flex  w-fit overflow-x-hidden ">
          <div className="slider-faq mx-10 flex items-center justify-center gap-20 md:mx-20 md:gap-40">
            <img
              src={cryptoFT}
              className="h-[4.5rem] w-[fit] lg:h-[5.5rem]"
              alt=""
            />
            <img
              src={binance}
              className="h-[4.5rem] w-[fit] lg:h-[5.5rem]"
              alt=""
            />
            <img
              src={emiratesNBD}
              className="h-[4.5rem] w-[fit] lg:h-[5.5rem]"
              alt=""
            />
            <img
              src={coinDCX}
              className="h-[4.5rem] w-[fit] lg:h-[5.5rem]"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  );
}

import React, { useState } from "react";
import Header from "./components/Header";
import FooterSection from "./sections/FooterSection";
import { useNavigate } from "react-router-dom";

function TickIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
        fill="#00D084"
      />
    </svg>
  );
}

function TickIconGD() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9556 5.90223L9.05063 19.5079L3.04199 13.4992L4.5004 12.0408L8.95017 16.4906L19.4034 4.54407L20.9556 5.90223Z"
        fill="url(#paint0_linear_1420_993)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1420_993"
          x1="20.9556"
          y1="12.026"
          x2="3.04199"
          y2="12.026"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9B51E0" />
          <stop offset="0.9999" stopColor="#F78DA7" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default function Plans() {
  const [pricing, setPricing] = useState("monthly");

  const navigate = useNavigate();

  const handleClickScroll = (id) => {
    navigate(`/#${id}`);
  };

  return (
    <section className="text-white">
      <Header onPlans={true} />
      <section className="flex w-full items-center justify-center">
        <div className=" flex flex-col items-center justify-center p-2 md:p-8">
          <div>
            {/* <h1 className="text-4xl font-semibold">
              Our <span className="text-gd">Plans</span> and Pricing.
            </h1> */}
            <h1 className="text-4xl font-semibold">
              Our <span className="text-gd">NEW</span> plans and pricing
            </h1>
            <p>Choose perfect plan for your investment needs</p>
          </div>
          <div className="my-8 flex items-center justify-center">
            {/* <div className="w-fit  rounded-3xl border-[.5px] border-[#717171] bg-[#222222] p-1">
              <button
                className={` w-[6rem]  rounded-3xl px-1.5 py-2 text-[14px] font-semibold ${
                  pricing === "monthly"
                    ? "bg-gd text-white"
                    : "bg-[#ffffff00] text-[#717171]"
                }`}
                onClick={() => {
                  setPricing("monthly");
                }}
              >
                Meta
              </button>
              <button
                className={` w-[6rem]  rounded-3xl px-1.5 py-2 text-[14px] font-semibold ${
                  pricing === "yearly"
                    ? "bg-gd text-white"
                    : "bg-[#ffffff00] text-[#717171]"
                }`}
                onClick={() => {
                  setPricing("yearly");
                }}
              >
                Premium
              </button>
            </div> */}
          </div>
          <div className="flex items-center justify-center gap-4">
            <div className="flex h-fit w-full flex-col items-center justify-center gap-5 md:w-[65rem] md:flex-row lg:items-start">
              {pricing === "monthly" ? (
                <>
                  <div className="flex w-full min-w-[24rem] flex-1 flex-col gap-4 rounded-lg border-[.6px] border-t-[3px] border-[#D4D4D4]/20 border-t-[#F78DA7] bg-[#717171]/20 px-6 py-8">
                    <h2 className="text-lg font-semibold">
                      Steady Growth Plan
                    </h2>
                    <ul className="mt-4 flex flex-col gap-3 font-light">
                      <span className="font-semibold">All Features</span>
                      <li className="flex gap-4">
                        <TickIcon />
                        8.72% monthly return (incl. of taxes)
                      </li>
                      <li className="flex gap-4">
                        <TickIcon />
                        Principal returned in the 12th month
                      </li>
                      <li className="flex gap-4">
                        <TickIcon />
                        $35,999 minimum to participate
                      </li>
                      <li className="flex gap-4">
                        <TickIcon />
                        Steady income stream
                      </li>
                      <li className="flex gap-4">
                        <TickIcon />
                        11 months of consistent returns
                      </li>
                    </ul>
                    <button
                      className="bg-gd mt-7 w-full rounded-lg p-3 font-bold"
                      onClick={() => {
                        handleClickScroll("contact-us");
                      }}
                    >
                      Get Started
                    </button>
                  </div>
                  <div className="flex min-w-[24rem] flex-1 flex-col gap-4 rounded-lg border-[.6px] border-t-[3px] border-[#D4D4D4]/20 border-t-[#9B51E0] bg-[#717171]/20 px-6 py-8">
                    <h2 className="text-lg font-semibold">
                      Enhanced Yield Plan
                    </h2>
                    <ul className="mt-4 flex flex-col gap-3 font-light">
                      <span className="font-semibold">All Features</span>
                      <li className="flex gap-4">
                        <TickIcon /> 18% payout every 2 months (incl. of taxes)
                      </li>
                      <li className="flex gap-4">
                        <TickIcon />
                        Gains subject to market risks for up to 13 months
                      </li>
                      <li className="flex gap-4">
                        <TickIcon />
                        $75,999 minimum to participate
                      </li>
                      <li className="flex gap-4">
                        <TickIcon />
                        Attractive yield for higher investments
                      </li>
                      <li className="flex gap-4">
                        <TickIcon />
                        Returns subject to market risks post principal return
                      </li>
                    </ul>
                    <button
                      className="bg-gd mt-7 w-full rounded-lg p-3 font-bold"
                      onClick={() => {
                        handleClickScroll("contact-us");
                      }}
                    >
                      Get Started
                    </button>
                  </div>
                  <div className="flex min-w-[24rem] flex-1 flex-col gap-4 rounded-lg border-[.6px] border-t-[3px] border-[#D4D4D4]/20 border-t-[#00D084] bg-[#717171]/20 px-6 py-8">
                    <h2 className="text-lg font-semibold">
                      Quarterly High-Roller Plan
                    </h2>
                    <ul className="mt-4 flex flex-col gap-3 font-light">
                      <span className="font-semibold">All Features</span>
                      <li className="flex gap-4">
                        <TickIcon /> Up to 38.99% per payout
                      </li>
                      <li className="flex gap-4">
                        <TickIcon />
                        Payouts every 3 months
                      </li>
                      <li className="flex gap-4">
                        <TickIcon />
                        $1,50,000 minimum to participate
                      </li>
                      <li className="flex gap-4">
                        <TickIcon />
                        Principal returned at the end of investment cycle
                      </li>
                      {/* <li className="flex gap-4">
                        <TickIcon />
                        Payouts distributed every 4 months
                      </li> */}
                    </ul>
                    <button
                      className="bg-gd mt-7 w-full rounded-lg p-3 font-bold"
                      onClick={() => {
                        handleClickScroll("contact-us");
                      }}
                    >
                      Get Started
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="flex flex-1 flex-col gap-4 rounded-lg border-[.6px] border-t-[3px] border-[#D4D4D4]/20 border-t-[#00D084] bg-[#717171]/20 px-6 py-8">
                    <h2 className="text-lg font-semibold">
                      Long Term Capital Growth Plan
                    </h2>

                    <ul className="mt-4 flex flex-col gap-3 font-light">
                      <span className="font-semibold">All Features</span>
                      <li className="flex gap-4">
                        <TickIconGD />
                        <span>
                          Return :<span className="font-bold"> Up to 55%</span>{" "}
                          per payout
                        </span>
                      </li>
                      <li className="flex gap-4">
                        <TickIconGD />
                        Payout distributed every five months
                      </li>
                      <li className="flex gap-4">
                        <TickIconGD />
                        <span>
                          Minimum Investment :
                          <span className="font-bold"> $1,00,000</span>
                        </span>
                      </li>
                      <li className="flex gap-4">
                        <TickIconGD />5 years of locking period
                      </li>
                    </ul>
                    <button
                      className="bg-gd mt-7 w-full rounded-lg p-3 font-bold"
                      onClick={() => {
                        handleClickScroll("contact-us");
                      }}
                    >
                      Get Started
                    </button>
                  </div>
                  <div className="flex h-full w-full flex-1 flex-col gap-4 rounded-lg border-[.6px] border-t-[3px] border-[#D4D4D4]/20 border-t-[#F78DA7] bg-[#717171]/20 px-6 py-8">
                    <h2 className="text-lg font-semibold">
                      Elite Investor Plan
                    </h2>

                    <ul className="mt-4 flex flex-col gap-3 font-light">
                      <span className="font-semibold">All Features</span>
                      <li className="flex gap-4">
                        <TickIconGD />{" "}
                        <span>
                          Return :<span className="font-bold"> Up to 68%</span>{" "}
                          per payout
                        </span>
                      </li>
                      <li className="flex gap-4">
                        <TickIconGD />
                        Payout distributed every six months
                      </li>
                      <li className="flex gap-4">
                        <TickIconGD />
                        <span>
                          Minimum Investment :
                          <span className="font-bold"> $10,00,000</span>
                        </span>
                      </li>
                      <li className="flex gap-4">
                        <TickIconGD />
                        Investment cycle of 6-7 years
                      </li>
                    </ul>
                    <button
                      className="bg-gd mt-7 w-full rounded-lg p-3 font-bold"
                      onClick={() => {
                        handleClickScroll("contact-us");
                      }}
                    >
                      Get Started
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
      <FooterSection />
    </section>
  );
}

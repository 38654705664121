import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa6";
import EmptyWallet from "./EmptyWallet";
import Modal from "./Modal";
import Wallet from "./Wallet";
import axios from "axios";

import TotalSpent from "views/admin/default/components/TotalSpent";
import Loader from "components/loader/Loader";

import { refresh } from "aos";

export default function PortfolioContainer() {
  const [wallet, setWallet] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [coinlist, setCoinlist] = useState([]);
  const [coininfo, setCoininfo] = useState([]);
  const [totalSpent, setTotalSpent] = useState(0);
  const [visible, setVisible] = useState(true);
  const [loading, setLoading] = useState(true);
  const uid = localStorage.getItem("id");
  const openPopUp = () => {
    setOpenModal(true);
  };

  const closePopUp = () => {
    setOpenModal(false);
  };

  const refreshData = () => {
    setLoading(true);
    axios
      .get(process.env.REACT_APP_BASE_URL + `/portfolio/coins`,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
      .then((res) => {
        const coininfo = res.data;
        const usercoininfo = coininfo.filter((coin) => coin.uid === uid);
        let totalSpent = 0;
        usercoininfo.map((coin) => {
          totalSpent += coin.totalSpent;
        });

        setTotalSpent(totalSpent);
        setCoininfo(usercoininfo);
        setLoading(false);
      });
  };

  useEffect(() => {
    Promise.all([
      axios.get(process.env.REACT_APP_BASE_URL + `/api/list`),
      axios.get(process.env.REACT_APP_BASE_URL + `/portfolio/coins`,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      }),
    ]).then(([coininfoRes, walletinfo]) => {
      const coinlist = coininfoRes.data.data;
      const coininfo = walletinfo.data;
      const usercoininfo = coininfo.filter((coin) => coin.uid === uid);
      let totalSpent = 0;
      usercoininfo.map((coin) => {
        totalSpent += coin.totalSpent;
      });

      setTotalSpent(totalSpent);
      console.log(totalSpent);

      setCoinlist(coinlist);
      setCoininfo(usercoininfo);

      setLoading(false);
    });
  }, []);

  return (
    <div>
      {loading ? (
        <div className="flex h-screen items-center justify-center">
          {/* <dotlottie-player
            src="https://lottie.host/42efddd6-e327-4839-88ba-098a0a6ef3f8/sg3Bewaizz.json"
            background="transparent"
            speed="1"
            style={{ height: "300px", width: "300px" }}
            loop
            autoplay
          ></dotlottie-player> */}
          <div className="scale-[52%]">
            <Loader className="" />
          </div>
        </div>
      ) : (
        <div className="z-999 flex min-h-[75vh] flex-col items-start justify-start  text-white">
          <div>
            {/* <h1 className="text-[30px] font-normal">My Portfolio</h1> */}
          </div>
          <div className="flex w-full items-center justify-between ">
            <div className="flex items-center text-[40px]">
              ${visible ? totalSpent.toFixed(2) : "********"}{" "}
              <button
                onClick={() => {
                  setVisible(!visible);
                }}
              >
                {visible ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    className="ml-3"
                  >
                    <path
                      d="M23.931 11.676C23.896 11.597 23.049 9.718 21.166 7.835C18.657 5.326 15.488 4 12 4C8.512 4 5.34299 5.326 2.83399 7.835C0.950991 9.718 0.0999905 11.6 0.0689905 11.676C0.0235036 11.7783 0 11.889 0 12.001C0 12.113 0.0235036 12.2237 0.0689905 12.326C0.103991 12.405 0.950991 14.283 2.83399 16.166C5.34299 18.674 8.512 20 12 20C15.488 20 18.657 18.674 21.166 16.166C23.049 14.283 23.896 12.405 23.931 12.326C23.9765 12.2237 24 12.113 24 12.001C24 11.889 23.9765 11.7783 23.931 11.676ZM12 18.4C8.922 18.4 6.233 17.281 4.00699 15.075C3.09367 14.1667 2.31662 13.1309 1.69999 12C2.31638 10.8689 3.09346 9.83315 4.00699 8.925C6.233 6.719 8.922 5.6 12 5.6C15.078 5.6 17.767 6.719 19.993 8.925C20.9081 9.833 21.6868 10.8687 22.305 12C21.584 13.346 18.443 18.4 12 18.4ZM12 7.2C11.0506 7.2 10.1226 7.48152 9.33326 8.00895C8.5439 8.53638 7.92868 9.28603 7.56537 10.1631C7.20207 11.0402 7.10702 12.0053 7.29223 12.9364C7.47744 13.8675 7.93459 14.7228 8.60588 15.3941C9.27718 16.0654 10.1325 16.5226 11.0636 16.7078C11.9947 16.893 12.9598 16.7979 13.8369 16.4346C14.714 16.0713 15.4636 15.4561 15.9911 14.6667C16.5185 13.8774 16.8 12.9494 16.8 12C16.7987 10.7274 16.2925 9.50724 15.3927 8.60735C14.4928 7.70746 13.2726 7.20132 12 7.2ZM12 15.2C11.3671 15.2 10.7484 15.0123 10.2222 14.6607C9.69594 14.3091 9.28578 13.8093 9.04358 13.2246C8.80138 12.6399 8.73801 11.9965 8.86148 11.3757C8.98496 10.755 9.28973 10.1848 9.73726 9.73726C10.1848 9.28973 10.755 8.98496 11.3757 8.86149C11.9965 8.73801 12.6399 8.80138 13.2246 9.04359C13.8093 9.28579 14.3091 9.69594 14.6607 10.2222C15.0123 10.7484 15.2 11.3671 15.2 12C15.2 12.8487 14.8629 13.6626 14.2627 14.2627C13.6626 14.8629 12.8487 15.2 12 15.2Z"
                      fill="white"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    className="ml-3"
                  >
                    <path
                      d="M23.931 11.676C23.896 11.597 23.049 9.718 21.166 7.835C18.657 5.326 15.488 4 12 4C8.512 4 5.34299 5.326 2.83399 7.835C0.950991 9.718 0.0999905 11.6 0.0689905 11.676C0.0235036 11.7783 0 11.889 0 12.001C0 12.113 0.0235036 12.2237 0.0689905 12.326C0.103991 12.405 0.950991 14.283 2.83399 16.166C5.34299 18.674 8.512 20 12 20C15.488 20 18.657 18.674 21.166 16.166C23.049 14.283 23.896 12.405 23.931 12.326C23.9765 12.2237 24 12.113 24 12.001C24 11.889 23.9765 11.7783 23.931 11.676ZM12 18.4C8.922 18.4 6.233 17.281 4.00699 15.075C3.09367 14.1667 2.31662 13.1309 1.69999 12C2.31638 10.8689 3.09346 9.83315 4.00699 8.925C6.233 6.719 8.922 5.6 12 5.6C15.078 5.6 17.767 6.719 19.993 8.925C20.9081 9.833 21.6868 10.8687 22.305 12C21.584 13.346 18.443 18.4 12 18.4ZM12 7.2C11.0506 7.2 10.1226 7.48152 9.33326 8.00895C8.5439 8.53638 7.92868 9.28603 7.56537 10.1631C7.20207 11.0402 7.10702 12.0053 7.29223 12.9364C7.47744 13.8675 7.93459 14.7228 8.60588 15.3941C9.27718 16.0654 10.1325 16.5226 11.0636 16.7078C11.9947 16.893 12.9598 16.7979 13.8369 16.4346C14.714 16.0713 15.4636 15.4561 15.9911 14.6667C16.5185 13.8774 16.8 12.9494 16.8 12C16.7987 10.7274 16.2925 9.50724 15.3927 8.60735C14.4928 7.70746 13.2726 7.20132 12 7.2ZM12 15.2C11.3671 15.2 10.7484 15.0123 10.2222 14.6607C9.69594 14.3091 9.28578 13.8093 9.04358 13.2246C8.80138 12.6399 8.73801 11.9965 8.86148 11.3757C8.98496 10.755 9.28973 10.1848 9.73726 9.73726C10.1848 9.28973 10.755 8.98496 11.3757 8.86149C11.9965 8.73801 12.6399 8.80138 13.2246 9.04359C13.8093 9.28579 14.3091 9.69594 14.6607 10.2222C15.0123 10.7484 15.2 11.3671 15.2 12C15.2 12.8487 14.8629 13.6626 14.2627 14.2627C13.6626 14.8629 12.8487 15.2 12 15.2Z"
                      fill="white"
                    />
                  </svg>
                )}
              </button>
            </div>
            <div></div>
            <button
              onClick={openPopUp}
              className="bg-gd flex items-center justify-between rounded-xl py-2 px-2"
            >
              <FaPlus /> <span className="ml-2">Add Transaction</span>
            </button>
          </div>

          {coininfo.length === 0 ? (
            <EmptyWallet openPopUp={openPopUp} />
          ) : (
            <div className="flex flex-col">
              <Wallet />
            </div>
          )}

          {openModal && (
            <Modal
              onTransactionSuccess={refreshData}
              coinlist={coinlist}
              closePopUp={closePopUp}
            />
          )}
        </div>
      )}
    </div>
  );
}

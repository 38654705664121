import React from "react";
import Header from "./components/Header";
import FooterSection from "./sections/FooterSection";
import FeaturesSection from "./sections/FeaturesSection";
import Certificate from "./sections/Certificate";

export default function AboutUs() {
  return (
    <div className="text-white">
      <Header onAboutUs={true} />
      <Certificate gd={true} />

      <FeaturesSection gd={true} />
      <FooterSection />
    </div>
  );
}

import axios from "axios";
import { useEffect, useState } from "react";
import Card from "components/card";
import BG from "./../../../../assets/img/dashboards/fg.png";

const DailyTraffic = () => {
  const [value, setValue] = useState(50);
  const [text, setText] = useState("");
  const [angle, setAngle] = useState(0);

  useEffect(() => {
    axios.get("https://api.alternative.me/fng/").then((res) => {
      console.log("value", res.data.data[0].value);
      setValue(res.data.data[0].value);
      //divide into 6 categories
      if (res.data.data[0].value <= 16) {
        setText("Strong Bear");
      } else if (res.data.data[0].value <= 33) {
        setText("Bear");
      } else if (res.data.data[0].value <= 50) {
        setText("Bearish");
      } else if (res.data.data[0].value <= 66) {
        setText("Bullish");
      } else if (res.data.data[0].value <= 83) {
        setText("Bull");
      } else if (res.data.data[0].value <= 100) {
        setText("Strong Bull");
      }
    });

    const calculateAngle = (val) => {
      const min = -90;
      const max = 90;
      let angle = (val / 100) * (max - min) + min;
      angle = Math.floor(angle);
      console.log(angle);
      return angle;
    };

    setAngle(calculateAngle(value));
  }, [value]);

  return (
    <Card extra="pb-7 p-[20px]">
      <div>
        <h4 className="text-2xl font-bold text-navy-700 dark:text-white">
          Fear and Greed Index
        </h4>
      </div>
      <div className="relative flex h-[300px] w-full flex-row items-center justify-center pt-10 pb-0">
        <div className="relative flex flex-row items-center justify-center">
          <img src={BG} alt="Background" className="w-4/5" />
          <div className="absolute bottom-0 flex w-full items-center justify-center">
            <div className="relative h-fit">
              <svg
                width="100"
                height="150"
                viewBox="0 0 100 150"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  style={{
                    transform: `rotate(${angle}deg)`,
                    transformOrigin: "50px 130px", // Center needle over image
                  }}
                >
                  <path
                    d="M2.4209 119.095L9.5971 15.2221L16.1266 119.138L2.4209 119.095Z"
                    fill="white"
                    stroke="#404040"
                    strokeWidth="2"
                    transform="translate(34, 15)" // Adjust position to center
                  />
                </g>
                <circle
                  cx="45"
                  cy="130"
                  r="13.4644"
                  fill="white"
                  text={value}
                  stroke="#404040"
                  strokeWidth="2"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center text-center">
        {text && (
          <div
            className="bg-[#FF5656] px-7 py-2 "
            style={{
              borderRadius: "10px",
            }}
          >
            {text && text}
          </div>
        )}
      </div>
    </Card>
  );
};

export default DailyTraffic;

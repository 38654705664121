function Card(props) {
  const { variant, extra, children, ...rest } = props;
  return (
    <div
    className={`!z-5 relative flex flex-col rounded-[20px] bg-clip-border shadow-3xl shadow-shadow-500   dark:text-white dark:shadow-none ${extra}`}
    style={{
      backgroundColor:'#171717',
      border: '0.5px solid #404040',
    }}
      {...rest}
    >
      {children}
    </div>
  );
}

export default Card;

import Heading from "../components/Heading";
import Img1 from "../../../assets/img/landings/1.png";
import Img2 from "../../../assets/img/landings/2.png";
import Img3 from "../../../assets/img/landings/3.png";
import Img4 from "../../../assets/img/landings/1.png";
import Img5 from "../../../assets/img/landings/2.png";
import glowCyan from "../Assets/glowCyan.png";
import glowOrange from "../../../assets/img/landings/gloworange.png";
import glowpink from "../../../assets/img/landings/glowpink.png";
import "../../../assets/css/GlowMoving.css";
import { Link } from "react-router-dom";

const cards = [
  {
    id: 1,
    title: "240%",
    desc: "Yearly ROI",
    img: Img1,
    iframeSrc: "https://www.youtube.com/embed/hFiNaqHTDMY",
  },
  {
    id: 2,
    title: "1M+",
    desc: "Volume",
    img: Img2,
    iframeSrc: "https://www.youtube.com/embed/KUXpzEq42FY",
  },
  {
    id: 3,
    title: "100%",
    desc: "Safe and Transparent",
    img: Img3,
    iframeSrc: "https://www.youtube.com/embed/0Vrn3hxqnXA",
  },
];

export default function Stats() {
  return (
    <section className="relative flex w-full flex-col items-center gap-6 p-6 md:p-20">
      <h4
        className="text-app text-center text-4xl"
        style={{
          fontFamily: "DM Sans",
          fontWeight: "700",
        }}
      >
        Enter a Universe of
        <br />
        <span
          style={{
            background:
              "linear-gradient(to left, #9b51e0, #c655cc, #e162ba, #f076ad, #f78da7)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Integrated Investment
        </span>{" "}
        Solutions.
      </h4>

      <div className="grid w-full grid-cols-1 gap-6 pt-10 md:grid-cols-3">
        {cards.slice(0, 3).map((card) => (
          <div
            key={card.id}
            className="z-30 flex flex-col items-center gap-4 rounded-2xl bg-gradient-to-br from-white/10 p-6"
            style={{
              borderRadius: "20px",
              border: "1px solid rgba(113, 113, 113, 0.20)",
              background: "rgba(25, 25, 25, 0.30)",
              backdropFilter: "blur(10px)",
              fontFamily: "DM Sans",
            }}
          >
            <h4 className="text-7xl font-bold">{card.title}</h4>
            <span className="text-app_gray/70 text-2xl">{card.desc}</span>
          </div>
        ))}
      </div>
      <div className="z-30 flex w-full flex-col items-center gap-4 p-6 md:w-3/4">
        {/* <p className="text-center">
          Titan Technologies is a leading global crypto investment firm, we aim
          to deliver strong returns and shared success to those we serve and the
          world at large.
        </p> */}
        <Link to="/auth/user/signup">
          <button
            type="button"
            className="mt-0 rounded-lg bg-gradient-to-r from-[#9b51e0] to-[#f78da7] px-9 py-3 text-lg font-semibold text-white  hover:from-[#f78da7] hover:to-[#9b51e0] lg:mt-8"
            style={{
              background: `linear-gradient(to left, #9b51e0, #c655cc, #e162ba, #f076ad, #f78da7)`,
            }}
          >
            Get Started
          </button>
        </Link>
      </div>
      <img
        src={glowOrange}
        draggable="false"
        className="nonselectable moveGlowLB absolute top-[-170px] right-[-600px] !z-0 h-[1500px] w-[1550px]"
      />
    </section>
  );
}

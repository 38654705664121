import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Modalwcu({ setOpenModal, openModal }) {
  const modelRef = useRef();

  const [InvestedAmount, setInvestedAmount] = useState(0);
  const [Returns, setReturns] = useState(0);
  const [Payout, setPayout] = useState(0);
  const [flag ,setFlag] = useState(true); // [flag, setFlag
  const [ROI, setROI] = useState(0);

  const closeModal = (e) => {
    if (modelRef.current === e.target) {
      setOpenModal(false);
    }
  };
  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [amount, setAmount] = useState(0);
  const navigate = useNavigate();

  const handleCalculate = () => {
    const numAmount = parseFloat(amount);
    let returnRate = 0;
    let payout = 0;
  
    if (numAmount >= 10000 && numAmount <= 24999) {
      returnRate = 13.5;
      payout = (numAmount * returnRate)/100;
    } else if (numAmount >= 25000 && numAmount <= 49999) {
      returnRate = 17.5;
      payout =(numAmount * returnRate)/100;
    } else if (numAmount >= 50001 && numAmount <= 99999) {
      returnRate = 45;
      payout = numAmount * returnRate;
    } else if (numAmount >= 100000 && numAmount <= 1000000) {
      returnRate = 55;
      payout = (numAmount * returnRate)/100;
    } else if (numAmount < 10000) {
      alert("Minimum amount should be $10000");
      setAmount(0);
      return;
    } else if (numAmount >= 1000001) {
      returnRate = 68;
      payout = 500;
    }
  
    if (numAmount >= 10000) {
      setInvestedAmount(numAmount);
      setReturns(returnRate);
      setPayout(payout);
  
      // Calculate ROI
      const calculatedROI = numAmount + (payout * 11);
      setROI(calculatedROI);
    }
  
    setFlag(false); // setFlag(false)
  };
  
  return (
    <div
      ref={modelRef}
      onClick={closeModal}
      className="fixed inset-0 top-0 left-0 z-50 flex h-screen w-screen items-center justify-center bg-[#252525]/70 bg-opacity-80 backdrop-blur-[15px]"
    >
      <div className="flex min-h-[400px] w-fit min-w-[400px] flex-col gap-6 rounded-xl border-[1px] border-[#404040] bg-[#222222] py-16 px-12">
        {flag? (
          <>
            <h2 className="text-[24px] font-bold">Calculate Your Returns</h2>
            <div className="flex flex-col gap-2">
              <span>Investment Amount($)</span>
              <input
                type="number"
                required
                className="rounded-lg border-[.4px] border-[#F9F9F9]/10 bg-[#ffffff00] px-4 py-2 outline-none md:w-[400px]"
              
                value={amount}
                placeholder="Enter Amount"
                onChange={(e) => 
                  setAmount(e.target.value)
                }
              />
            </div>
            <div className="flex flex-col gap-3.5">
              <button
                onClick={handleCalculate}
                className="w-full rounded-lg bg-gradient-to-r from-[#F78DA7] to-[#9B51E0] py-2"
              >
                Calculate
              </button>
              <button
                onClick={() => setOpenModal(false)}
                className="w-full rounded-lg border-[.4px] border-[#D4D4D4]/40 py-2 text-[#D4D4D4]"
              >
                Cancel
              </button>
            </div>
          </>
        ) : (
          <div className="flex flex-col gap-4">
            <h2 className="text-[24px] font-bold">Your Returns</h2>

            <div className="rounded-lg border-[.4px] border-[#404040] bg-[#252525] px-3 py-2.5 md:w-[300px]">
              <span className="mr-2 text-sm text-[#717171]">Amount Invested</span>
              $<span>{InvestedAmount}</span>
            </div>
            <div className="rounded-lg border-[.4px] border-[#404040] bg-[#252525] px-3 py-2.5 md:w-[300px]">
              <span className="mr-2 text-sm text-[#717171]">Returns</span>
              <span>{Returns}</span>%
            </div>
            <div className="rounded-lg border-[.4px] border-[#404040] bg-[#252525] px-3 py-2.5 md:w-[300px]">
              <span className="mr-2 text-sm text-[#717171]">Payout</span>
              $<span>{Payout}</span>/per month
            </div>
            <div className="flex flex-col rounded-lg border-[.4px] border-[#404040] bg-[#252525] px-3 py-2.5 md:w-[300px]">
              <span className="mb-1 text-sm text-[#717171]">ROI at complete cycle</span>
              <span>${ROI}</span>
            </div>
            <div className="flex flex-col gap-3.5">
              <button
                onClick={() => {
                  setOpenModal(false);

                  handleClickScroll('contact-us')

                  
                }}
                className="w-full rounded-lg bg-gradient-to-r from-[#F78DA7] to-[#9B51E0] py-2.5 text-sm font-normal"
              >
                Start Investment
              </button>
              <button
                onClick={() => setFlag(true)}
                className="w-full rounded-lg border-[.4px] border-[#D4D4D4] py-2.5 text-sm font-normal text-[#D4D4D4]"
              >
                Calculate Again
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { TbTriangleFilled, TbTriangleInvertedFilled } from "react-icons/tb";
import { GoTriangleDown } from "react-icons/go";
import { BsInfoCircle } from "react-icons/bs";
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";
import Chart from "react-apexcharts";
import Loader2 from "components/loader2/Loader2";
import Btnleft from "components/buttonarrow/Btnleft";
import Btnright from "components/buttonarrow/Btnright";
import Loader from "components/loader/Loader";
import Pagination from "components/pagination/Pagination";

function removeLeadingSign(num) {
  const numStr = num.toString();
  if (numStr[0] === "-" || numStr[0] === "+") {
    return parseFloat(numStr.substring(1));
  }
  return num;
}

export default function Table({ data }) {
  const [coindata, setCoindata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Number of items per page

  useEffect(() => {
    axios
      .get(
        "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=1&sparkline=false&price_change_percentage=1h&locale=en"
      )
      .then((res) => {
        setCoindata(res.data);

        setLoading(false);
      });
  }, []);

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  if (!data) {
    return <div>No data to show</div>;
  }

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = coindata.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(coindata.length / itemsPerPage);

  // Change page
  const paginate = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };
  const pagesArray = Array.from({ length: totalPages }, (_, i) => i + 1);

  return (
    <div>
      {loading ? (
        <div className="flex w-full items-center  justify-center py-6">
          <div className="scale-[58%]">
            <Loader />
          </div>
        </div>
      ) : (
        <>
          {" "}
          <table className="!z-20 w-full overflow-hidden rounded-xl text-[13px] font-light text-white lg:text-[15px]">
            <thead className="rounded-xl bg-[#404040] bg-opacity-40 font-light">
              <tr>
                <th className="py-3 px-4 text-left">Asset</th>
                <th className="py-3 px-4 text-left">Price (USD)</th>
                {/* <th className="flex items-center py-3 px-4  text-left">
                  24h <GoTriangleDown />
                </th> */}
                <th className="flex items-center py-3 px-4 text-left">
                  <div className="flex items-center justify-center">
                    <select className=" appearance-none border-none bg-[#ffffff00] outline-none">
                      <option className="bg-[#ffffff00]" value="24h">
                        24H
                      </option>
                      <option className="bg-[#ffffff00]" value="7h">
                        7H
                      </option>
                      <option className="bg-[#ffffff00]" value="7h">
                        7D
                      </option>
                    </select>
                    <GoTriangleDown />
                  </div>
                </th>
                <th className="py-3 px-4 text-left">
                  <span className="flex items-center gap-2">
                    Market Cap <BsInfoCircle />
                  </span>
                </th>
                <th className="py-3 px-4 text-left">
                  <span className="flex items-center gap-2">
                    Volume <BsInfoCircle />
                  </span>
                </th>
                <th className="py-3 px-4 text-left">Graph</th>
              </tr>
            </thead>
            <tbody className="rounded-b-xl bg-[#252525] bg-opacity-40">
              {currentItems.map((item, index) => (
                <tr key={index} className="my-4 ">
                  <td className="mt-5 flex items-center gap-3 py-3 px-4">
                    <img
                      src={item.image}
                      alt="Logo"
                      className="h-8 w-8 rounded-full"
                    />

                    <div className="flex flex-col">
                      <span>{item.name}</span>
                      <span className="text-xs uppercase text-[#717171]">
                        {item.symbol}
                      </span>
                    </div>
                  </td>

                  <td className="py-3 px-4">
                    {formatter.format(item.current_price)}
                  </td>
                  <td className="flex items-center  gap-1 py-3 px-4">
                    {item.price_change_percentage_24h > 0 ? (
                      <TbTriangleFilled className="text-green-500" />
                    ) : (
                      <TbTriangleInvertedFilled className="text-red-500" />
                    )}
                    <span
                      className={
                        item.price_change_percentage_24h > 0
                          ? "text-green-500"
                          : "text-red-500"
                      }
                    >
                      {item.price_change_percentage_24h > 0 ? "+" : "-"}
                      {removeLeadingSign(item.price_change_percentage_24h)}%
                    </span>
                  </td>
                  <td className="py-3 px-4">
                    {formatter.format(item.market_cap)}
                  </td>
                  <td className="py-3 px-4">
                    {formatter.format(item.total_volume)}
                  </td>
                  <td className="py-3 px-4">
                    <ChartComponent
                      coinId={item.id}
                      isProfit={item.price_change_percentage_24h > 0}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="mt-5 flex w-full justify-between px-2 text-[32px] text-white">
            <FaArrowCircleLeft
              className={`cursor-pointer ${
                currentPage === 1 ? "opacity-50" : "opacity-100"
              }`}
              onClick={() => paginate(currentPage - 1)}
            />
            <div className="flex cursor-pointer gap-2">
              {pagesArray.map((page) => (
                <div
                  key={page}
                  className={`flex items-center justify-center rounded-full border-[.4px] border-[#ffffff]/20 p-[.5px]`}
                  onClick={() => paginate(page)}
                >
                  <div
                    className={`flex h-8 w-8 items-center justify-center rounded-full ${
                      currentPage !== page
                        ? "bg-[#010101]"
                        : "bg-gradient-to-r from-[#F78DA7] to-[#9B51E0]"
                    } text-sm`}
                  >
                    <span
                      className={` block ${
                        currentPage !== page ? "text-gd" : "text-white"
                      } `}
                    >
                      {page}
                    </span>
                  </div>
                </div>
              ))}
            </div>

            <FaArrowCircleRight
              className={`cursor-pointer ${
                currentPage === totalPages ? "opacity-50" : "opacity-100"
              }`}
              onClick={() => paginate(currentPage + 1)}
            />
            {/* <Btnleft
              className={`cursor-pointer ${
                currentPage === 1 ? "opacity-50" : "opacity-100"
              }`}
              onClick={() => paginate(currentPage - 1)}
            />
            <Btnright
              className={`cursor-pointer ${
                currentPage === totalPages ? "opacity-50" : "opacity-100"
              }`}
              onClick={() => paginate(currentPage + 1)}
            /> */}
          </div>
        </>
      )}
    </div>
  );
}
function ChartComponent({ coinId, isProfit }) {
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchChartData(coinId);
      setChartData(data);
      setLoading(false);
    };

    fetchData();
  }, [coinId]);

  const fetchChartData = async (id) => {
    const response = await axios.get(
      `https://api.coingecko.com/api/v3/coins/${id}/market_chart?vs_currency=usd&days=7`
    );
    return response.data.prices.map((price) => ({
      x: new Date(price[0]),
      y: price[1],
    }));
  };

  const options = {
    chart: {
      type: "line",
      height: 100,
      sparkline: {
        enabled: true,
      },
    },
    stroke: {
      curve: "smooth",
      width: 2,
      colors: [isProfit ? "#22C55E" : "#FF0000"], // Green for profit, Red for loss
    },
    tooltip: {
      enabled: false,
    },
  };

  const series = [
    {
      name: "Price",
      data: chartData,
    },
  ];

  return (
    <div className="h-20 w-32">
      {loading ? (
        // <div>Loading...</div>
        <Loader2 />
      ) : (
        <Chart options={options} series={series} type="line" height={100} />
      )}
    </div>
  );
}

import Footer from "components/footer/FooterAuthDefault";
import authImg from "assets/img/auth/auth.png";
import { Link, Routes, Route, Navigate } from "react-router-dom";
import routes from "routes.js";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import SignIn from "views/auth/user/SignIn";
import SignUp from "views/auth/user/SignUp";
import { GoogleOAuthProvider } from '@react-oauth/google';
export default function Auth() {
  
  return (
    <div>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <div className="">
        <Routes>
          <Route path="/login" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="*" element={<Navigate to="/auth/user/login " />} />
        </Routes>
      </div>
      </GoogleOAuthProvider>
    </div>
  );
}

export default function Heading({ heading, subHeading, className = "" }) {
    return (
      <div className={`${className}`}
        style={{
          fontFamily: 'DM Sans',
            fontWeight: '700',
            marginBottom: '2rem',
        }}
      >
        <h4
         
        className="font-redzone text-app text-4xl">{heading}</h4>
        <h2 
         style={{
          background: 'linear-gradient(to left, #9b51e0, #c655cc, #e162ba, #f076ad, #f78da7)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          // fontamily: "DM Sans", sans-serif;
          

        }}
        className="font-redzone text-4xl">{subHeading}</h2>
      </div>
    )
  }
  
import React from "react";
import "./loader.css";
export default function Loader() {
  return (
    <>
      <div className="spinner">
        <div className="spinner1"></div>
      </div>
    </>
  );
}

import React from "react";

import { FaPlus } from "react-icons/fa6";
import GoldenCoin from "../Assests/GoldenCoin.png";
export default function EmptyWallet({ openPopUp }) {
  return (
    <div className="flex h-full w-full flex-grow items-center justify-center ">
      <div className="flex flex-col items-center justify-center gap-4">
        <img src={GoldenCoin} alt="" />
        <p className="text-[#8C8C8C]">Add a coin to begin</p>
        <button
          onClick={openPopUp}
          className="bg-gd flex items-center gap-1 rounded-md px-3 py-2 text-[14px]"
        >
          <FaPlus />
          Add a transaction
        </button>
      </div>
    </div>
  );
}

import MiniCalendar from "components/calendar/MiniCalendar";
import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import TotalSpent from "views/admin/default/components/TotalSpent";
import PieChartCard from "./components/PieChartCard";
import { IoMdHome } from "react-icons/io";
import { IoDocuments } from "react-icons/io5";
import { MdBarChart, MdDashboard } from "react-icons/md";

import { columnsDataCheck, columnsDataComplex } from "./variables/columnsData";
import { useEffect, useState } from "react";
import Widget from "components/widget/Widget";
import CheckTable from "./components/CheckTable";
import DailyTraffic from "./components/DailyTraffic";

import tableDataCheck from "./variables/tableDataCheck.json";
import ComplexTable from "../tables/components/ComplexTable";
import axios from "axios";
import Loader from "components/loader/Loader";

function GreenTick() {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.875 11.625L12.25 1.25M12.25 1.25V7.5M12.25 1.25H6.0625"
        stroke="#2DC24E"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
function RedTick() {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.875 1.25L12.25 11.625M12.25 11.625V5.375M12.25 11.625H6.0625"
        stroke="#E0042C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
function WalletIcon() {
  return (
    <svg
      width="30"
      height="25"
      viewBox="0 0 30 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.09321 7.37337V13.2401M20.349 7.3737V13.2404M24.4534 6.86661V13.7333C24.4534 17.84 22.1067 19.6 18.5867 19.6H6.86665C6.26665 19.6 5.69333 19.5466 5.16 19.4266C4.82667 19.3733 4.50667 19.28 4.21333 19.1733C2.21333 18.4266 1 16.6933 1 13.7333V6.86661C1 2.75995 3.34665 1 6.86665 1H18.5867C21.5734 1 23.7201 2.26665 24.2934 5.15999C24.3867 5.69332 24.4534 6.23995 24.4534 6.86661ZM28.4549 10.8668V17.7334C28.4549 21.8401 26.1082 23.6001 22.5882 23.6001H10.8682C9.8815 23.6001 8.98818 23.4668 8.21485 23.1734C6.62818 22.5868 5.54817 21.3734 5.1615 19.4268C5.69484 19.5468 6.26817 19.6001 6.86817 19.6001H18.5882C22.1082 19.6001 24.4549 17.8401 24.4549 13.7334V6.86677C24.4549 6.2401 24.4016 5.68016 24.2949 5.16016C26.8282 5.69349 28.4549 7.48012 28.4549 10.8668ZM12.7177 13.8265C14.6617 13.8265 16.2377 12.2505 16.2377 10.3065C16.2377 8.36244 14.6617 6.78645 12.7177 6.78645C10.7737 6.78645 9.19774 8.36244 9.19774 10.3065C9.19774 12.2505 10.7737 13.8265 12.7177 13.8265Z"
        stroke="url(#paint0_linear_1155_3933)"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1155_3933"
          x1="28.4549"
          y1="12.3001"
          x2="1"
          y2="12.3001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#9B51E0" />
          <stop offset="0.9999" stop-color="#F78DA7" />
        </linearGradient>
      </defs>
    </svg>
  );
}

function MoneyIcon() {
  return (
    <svg
      width="28"
      height="26"
      viewBox="0 0 28 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.71876 26C4.62062 26 2.08228 23.751 2.08228 20.9819V18.3298C2.08228 17.7968 2.52487 17.3548 3.05858 17.3548C3.59229 17.3548 4.03489 17.7968 4.03489 18.3298C4.03489 19.9549 5.60994 21.1769 7.71876 21.1769C9.82758 21.1769 11.4027 19.9549 11.4027 18.3298C11.4027 17.7968 11.8453 17.3548 12.379 17.3548C12.9127 17.3548 13.3553 17.7968 13.3553 18.3298V20.9819C13.3553 23.751 10.8299 26 7.71876 26ZM4.37327 22.2559C4.94604 23.3219 6.23477 24.05 7.71876 24.05C9.20274 24.05 10.4915 23.3089 11.0642 22.2559C10.14 22.8149 8.99446 23.1399 7.71876 23.1399C6.44305 23.1399 5.29751 22.8149 4.37327 22.2559Z"
        fill="url(#paint0_linear_1155_3941)"
      />
      <path
        d="M7.71876 19.5646C5.5839 19.5646 3.67039 18.5896 2.72012 17.0426C2.30356 16.3665 2.08228 15.5735 2.08228 14.7675C2.08228 13.4025 2.68106 12.1285 3.77453 11.1794C5.88334 9.33345 9.51517 9.33351 11.637 11.1666C12.7305 12.1286 13.3423 13.4025 13.3423 14.7675C13.3423 15.5735 13.121 16.3665 12.7044 17.0426C11.7672 18.5896 9.85361 19.5646 7.71876 19.5646ZM7.71876 11.6996C6.7034 11.6996 5.76617 12.0375 5.06323 12.6485C4.39935 13.2205 4.03489 13.9745 4.03489 14.7675C4.03489 15.2225 4.15199 15.6387 4.3863 16.0287C4.9851 17.0167 6.26081 17.6277 7.71876 17.6277C9.17671 17.6277 10.4524 17.0167 11.0382 16.0417C11.2725 15.6647 11.3897 15.2357 11.3897 14.7806C11.3897 13.9876 11.0252 13.2335 10.3613 12.6485C9.67141 12.0375 8.73411 11.6996 7.71876 11.6996Z"
        fill="url(#paint1_linear_1155_3941)"
      />
      <path
        d="M7.71876 23.1399C4.50346 23.1399 2.08228 21.0734 2.08228 18.3434V14.7675C2.08228 11.9985 4.6076 9.7502 7.71876 9.7502C9.18972 9.7502 10.5956 10.2572 11.65 11.1672C12.7435 12.1292 13.3553 13.4033 13.3553 14.7683V18.3434C13.3553 21.0734 10.9341 23.1399 7.71876 23.1399ZM7.71876 11.6996C5.68804 11.6996 4.03489 13.0775 4.03489 14.7675V18.3434C4.03489 19.9684 5.60994 21.1904 7.71876 21.1904C9.82758 21.1904 11.4027 19.9684 11.4027 18.3434V14.7683C11.4027 13.9753 11.0382 13.2213 10.3744 12.6363C9.67142 12.0382 8.73411 11.6996 7.71876 11.6996Z"
        fill="url(#paint2_linear_1155_3941)"
      />
      <path
        d="M23.1705 15.6656C21.2049 15.6656 19.5387 14.2095 19.3825 12.3375C19.2783 11.2585 19.6689 10.2055 20.4499 9.43845C21.1008 8.76242 22.025 8.38543 23.0013 8.38543H25.722C27.0107 8.42443 28 9.43845 28 10.6865V13.3645C28 14.6126 27.0107 15.6266 25.761 15.6656H23.1705ZM25.6829 10.3355H23.0143C22.5587 10.3355 22.1422 10.5045 21.8428 10.8165C21.4653 11.1805 21.283 11.6745 21.3351 12.1685C21.4002 13.0265 22.2333 13.7155 23.1705 13.7155H25.722C25.8912 13.7155 26.0474 13.5595 26.0474 13.3645V10.6865C26.0474 10.4915 25.8912 10.3485 25.6829 10.3355Z"
        fill="url(#paint3_linear_1155_3941)"
      />
      <path
        d="M19.2136 24.0505H15.9593C15.4256 24.0505 14.983 23.6085 14.983 23.0755C14.983 22.5425 15.4256 22.1004 15.9593 22.1004H19.2136C22.5721 22.1004 24.746 19.9294 24.746 16.5753V15.6653L23.1705 15.6656C21.2049 15.6656 19.5387 14.2095 19.3825 12.3375C19.2783 11.2585 19.6689 10.2055 20.4499 9.43845C21.1008 8.76242 22.025 8.38543 23.0013 8.38543L24.733 8.38518V7.47515C24.733 4.43309 22.9498 2.34002 20.0599 2.00201C19.7475 1.95001 19.474 1.95004 19.2006 1.95004H7.48501C7.17259 1.95004 6.87317 1.97603 6.57377 2.01503C3.70995 2.37903 1.95261 4.45909 1.95261 7.47515V10.0752C1.95261 10.6082 1.51002 11.0502 0.976305 11.0502C0.442592 11.0502 0 10.6082 0 10.0752V7.47515C0 3.47107 2.47336 0.572024 6.31349 0.0910148C6.66496 0.0390137 7.06845 0 7.48501 0H19.2006C19.513 0 19.9166 0.0130002 20.3331 0.0780015C24.1733 0.52001 26.6856 3.43207 26.6856 7.47515V9.3602C26.6856 9.8932 26.243 10.3352 25.7093 10.3352H23.0017C22.5461 10.3352 22.1297 10.5042 21.8303 10.8162C21.4528 11.1802 21.2704 11.6742 21.3225 12.1682C21.3875 13.0263 22.2208 13.7153 23.158 13.7153L25.722 13.7155C26.2557 13.7155 26.6986 14.1573 26.6986 14.6903V16.5753C26.6986 21.0474 23.6916 24.0505 19.2136 24.0505Z"
        fill="url(#paint4_linear_1155_3941)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1155_3941"
          x1="28"
          y1="13"
          x2="0"
          y2="13"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#9B51E0" />
          <stop offset="0.9999" stop-color="#F78DA7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1155_3941"
          x1="28"
          y1="13"
          x2="0"
          y2="13"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#9B51E0" />
          <stop offset="0.9999" stop-color="#F78DA7" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1155_3941"
          x1="28"
          y1="13"
          x2="0"
          y2="13"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#9B51E0" />
          <stop offset="0.9999" stop-color="#F78DA7" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1155_3941"
          x1="28"
          y1="13"
          x2="0"
          y2="13"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#9B51E0" />
          <stop offset="0.9999" stop-color="#F78DA7" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1155_3941"
          x1="28"
          y1="13"
          x2="0"
          y2="13"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#9B51E0" />
          <stop offset="0.9999" stop-color="#F78DA7" />
        </linearGradient>
      </defs>
    </svg>
  );
}

function StatsIcon() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.539 22.0089H6.60319C6.06924 22.0089 5.62645 21.5661 5.62645 21.0322V14.3643C5.62645 13.0098 6.73341 11.9027 8.08783 11.9027H11.539C12.0729 11.9027 12.5157 12.3455 12.5157 12.8795V21.019C12.5157 21.566 12.0729 22.0089 11.539 22.0089ZM7.57994 20.0554H10.5622V13.8694H8.08783C7.81434 13.8694 7.57994 14.0906 7.57994 14.3772V20.0554Z"
        fill="url(#paint0_linear_1155_3950)"
      />
      <path
        d="M16.4609 22.0089H11.5251C10.9911 22.0089 10.5483 21.5661 10.5483 21.0322V8.4516C10.5483 7.09718 11.6553 5.99019 13.0097 5.99019H14.9892C16.3437 5.99019 17.4506 7.09718 17.4506 8.4516V21.0322C17.4376 21.5661 17.0078 22.0089 16.4609 22.0089ZM15.4972 20.0554V8.4516C15.4972 8.17811 15.2758 7.94368 14.9892 7.94368H13.0097C12.7362 7.94368 12.5018 8.16509 12.5018 8.4516V20.0554H15.4972Z"
        fill="url(#paint1_linear_1155_3950)"
      />
      <path
        d="M21.398 22.0089H16.4622C15.9282 22.0089 15.4854 21.5661 15.4854 21.0322V15.1065C15.4854 14.5725 15.9282 14.1297 16.4622 14.1297H19.9133C21.2678 14.1297 22.3747 15.2367 22.3747 16.5911V21.0322C22.3747 21.5661 21.945 22.0089 21.398 22.0089ZM17.4389 20.0554H20.4212V16.5911C20.4212 16.3176 20.1999 16.0832 19.9133 16.0832H17.4389V20.0554Z"
        fill="url(#paint2_linear_1155_3950)"
      />
      <path
        d="M17.907 28H10.093C3.0214 28 0 24.9786 0 17.907V10.093C0 3.0214 3.0214 0 10.093 0H17.907C24.9786 0 28 3.0214 28 10.093V17.907C28 24.9786 24.9786 28 17.907 28ZM10.093 1.95349C4.0893 1.95349 1.95349 4.0893 1.95349 10.093V17.907C1.95349 23.9107 4.0893 26.0465 10.093 26.0465H17.907C23.9107 26.0465 26.0465 23.9107 26.0465 17.907V10.093C26.0465 4.0893 23.9107 1.95349 17.907 1.95349H10.093Z"
        fill="url(#paint3_linear_1155_3950)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1155_3950"
          x1="28"
          y1="14"
          x2="0"
          y2="14"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#9B51E0" />
          <stop offset="0.9999" stop-color="#F78DA7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1155_3950"
          x1="28"
          y1="14"
          x2="0"
          y2="14"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#9B51E0" />
          <stop offset="0.9999" stop-color="#F78DA7" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1155_3950"
          x1="28"
          y1="14"
          x2="0"
          y2="14"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#9B51E0" />
          <stop offset="0.9999" stop-color="#F78DA7" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1155_3950"
          x1="28"
          y1="14"
          x2="0"
          y2="14"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#9B51E0" />
          <stop offset="0.9999" stop-color="#F78DA7" />
        </linearGradient>
      </defs>
    </svg>
  );
}
const Dashboard = () => {
  const [totalinvested, setTotalInvested] = useState(0);
  const [currentProfit, setCurrentProfit] = useState(0);
  const [currentValue, setCurrentValue] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [profitbook, setProfitbook] = useState([]);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [totalBalance, setTotalBalance] = useState(0);
  const [todayProfit, setTodayProfit] = useState(0);
  const [sevenDayProfit, setSevenDayProfit] = useState(0);
  const [thirtyDayProfit, setThirtyDayProfit] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [investmentres, profitbookRes, userRes] = await Promise.all([
          axios.get(process.env.REACT_APP_BASE_URL + "/investment/getinvestment",{
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          }),
          axios.get(process.env.REACT_APP_BASE_URL + "/investment/getprofitbook",{
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          }),
          axios.get(process.env.REACT_APP_BASE_URL + "/user/getuser",{
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          }),
        ]);

        let totalinvested = 0;
        let currentProfit = 0;
        let totalBalance = 0;

        setTotalUsers(userRes.data.length);
        setUsers(userRes.data);
        setProfitbook(profitbookRes.data);

        const users = userRes.data;
        const userMap = new Map(users.map((user) => [user._id, user]));

        const profitbookWithNames = profitbookRes.data.map((investment) => ({
          ...investment,
          name: userMap.get(investment.uid).name,
        }));

        setProfitbook(profitbookWithNames);

        investmentres.data.forEach((investment) => {
          totalinvested += investment.investamount;
        });
        profitbookRes.data.forEach((profit) => {
          currentProfit += profit.profitamount;
        });
        userRes.data.forEach((user) => {
          totalBalance += user.balance;
        });

        setTotalBalance(totalBalance);
        setTotalInvested(totalinvested);
        setCurrentProfit(currentProfit);
        setLoading(false);

        calculateProfitPercentages(profitbookRes.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const calculateProfitPercentages = (profitbook) => {
    const today = new Date();
    const oneDayMs = 24 * 60 * 60 * 1000;
    const startOfToday = new Date(today.setHours(0, 0, 0, 0));
    const startOf7DaysAgo = new Date(today.getTime() - 6 * oneDayMs);
    const startOf30DaysAgo = new Date(today.getTime() - 29 * oneDayMs);

    let todayProfitAmount = 0;
    let sevenDayProfitAmount = 0;
    let thirtyDayProfitAmount = 0;

    profitbook.forEach((profit) => {
      const profitDate = new Date(profit.profitdate);
      if (profitDate >= startOfToday) {
        todayProfitAmount += profit.profitamount;
      }
      if (profitDate >= startOf7DaysAgo) {
        sevenDayProfitAmount += profit.profitamount;
      }
      if (profitDate >= startOf30DaysAgo) {
        thirtyDayProfitAmount += profit.profitamount;
      }
    });

    const totalProfit = profitbook.reduce((sum, profit) => sum + profit.profitamount, 0);
    setTodayProfit(((todayProfitAmount / totalProfit) * 100).toFixed(1));
    setSevenDayProfit(((sevenDayProfitAmount / totalProfit) * 100).toFixed(1));
    setThirtyDayProfit(((thirtyDayProfitAmount / totalProfit) * 100).toFixed(1));
  };


 
  return (
    <div>
      {/* Card widget */}

      {loading ? (
        <div class=" flex h-screen items-center justify-center">
          {/* <dotlottie-player
            src="https://lottie.host/42efddd6-e327-4839-88ba-098a0a6ef3f8/sg3Bewaizz.json"
            background="transparent"
            speed="1"
            style={{
              height: "300px",
              width: "300px",
            }}
            loop
            autoplay
          ></dotlottie-player> */}
          <Loader class={"scale-[.6]"} />
        </div>
      ) : (
        <>
          <div class="my-6 text-white">
            Total Balance
            <div className="mt-2 flex w-full flex-col justify-between  md:flex-row ">
              <div className=" flex items-center ">
                <span className="text-4xl font-bold">${totalBalance}</span>
                {/* <div className="ml-3 flex h-6 w-fit items-center justify-center  rounded-3xl border-[.4px]  border-[#2DC24E] py-0.5 px-2 text-[10px] text-[#2DC24E]">
                  + 1.2%
                  <div className="scale-[.6]">
                    <GreenTick class="" />
                  </div>
                </div> */}
              </div>

              <div className="my-3 flex self-start md:my-0 ">
                <div className="px-6">
                  <span className="text-[13px] text-[#525252]">Today</span>
                  <div className="flex items-center text-[13px] text-[#2DC24E]">
                    {todayProfit}
                    <div className="scale-[.6]">
                      <GreenTick />
                    </div>
                  </div>
                </div>
                <div className="border-x-[.8px] border-[#222222] px-6">
                  <span className="text-[13px] text-[#525252]">7 Days</span>
                  <div className="flex items-center text-[13px] text-[#2DC24E]">
                    {sevenDayProfit}
                    <div className="scale-[.6]">
                      <GreenTick />
                    </div>
                  </div>
                </div>
                <div className="px-6">
                  <span className="text-[13px] text-[#525252]">30 Days</span>
                  <div className="flex items-center text-[13px] text-[#E0042C]">
                    {thirtyDayProfit}
                    <div className="scale-[.6]">
                      <RedTick />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
            <Widget
              icon={<WalletIcon className="h-7 w-7" />}
              title={"Total Invested"}
              subtitle={"$" + totalinvested}
              extra="center"
            />
            <Widget
              icon={<MoneyIcon className="h-6 w-6" />}
              title={"Current Profit"}
              subtitle={"$" + currentProfit}
            />
            <Widget
              icon={<StatsIcon className="h-7 w-7" />}
              title={"Total Users"}
              subtitle={totalUsers}
            />
            {/* <Widget
          icon={<MdDashboard className="h-6 w-6" />}
          title={"Your Balance"}
          subtitle={"$1,000"}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"New Tasks"}
          subtitle={"145"}
        />
        <Widget
          icon={<IoMdHome className="h-6 w-6" />}
          title={"Total Projects"}
          subtitle={"$2433"}
        /> */}
          </div>

          {/* Charts */}

          <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
            <PieChartCard users={users} />
            <DailyTraffic
              Switch={true}
              totalprofit={currentProfit}
              profitbook={profitbook}
            />
            {/* <WeeklyRevenue /> */}
          </div>

          {/* Tables & Charts */}
        </>
      )}
    </div>
  );
};

export default Dashboard;
